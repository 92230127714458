import {
  CssBaseline,
  ThemeProvider,
  buttonClasses,
  createTheme,
} from "@mui/material";

// Set up theme colors and breakpoints.
let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1A5BE0",
    },
    secondary: {
      main: "#3CD7CD",
    },
    background: {
      default: "#0E0B34",
      paper: "#0E0B34",
    },
  },
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1320,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          selected: {
            background: "#111",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          background: "#111",
        },
        paper: {
          border: "1px solid #FFF3",
          boxShadow: "0px 0px 10px #FFF5",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // background: "#1115",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: "#1115",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // background: "#1115",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
        },
        head: {
          background: "#111",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: "44px",
          background: "#FFF2",
        },
        root: {
          background: "#FFF1",
          "&:nth-of-type(even)": {
            background: "#FFF0",
          },
        },
      },
    },
  },
});

// Set up components that are affected by the theme options
theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Helvetica-Medium",
          textTransform: "none",
          borderRadius: "8px",
          fontSize: 16,
          [`${theme.breakpoints.up("sm")}`]: {
            padding: "12px 24px",
          },
          [`${theme.breakpoints.down("md")}`]: {
            padding: "8px 16px",
          },
        },
      },
    },
  },
});

export default function ThemeManager(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
