import { Badge, Button, Stack, Typography } from "@mui/material";
import { LOGO } from "../../assets";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Check, Email } from "@mui/icons-material";
import { RestPostVerification } from "../../core/rest";
import { useLocation, useNavigate } from "react-router";
import { applyActionCode, signInWithCustomToken } from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import GlobalFooter from "../landing/components/global-footer";

export default function PageVerification() {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);
  const loc = useLocation();
  const code = loc.state?.code;
  const params = new URLSearchParams(loc.search);
  const email = loc.state?.email;
  const stamp = loc.state?.stamp;
  const navigate = useNavigate();

  async function verifyEmail() {
    debugger
    try {
      setBusy(true);
      // Proceed.
      const userCredential = await signInWithCustomToken(FbAuth, code!);
      const idToken = await userCredential.user.getIdToken(); // This is the ID token
      // await applyActionCode(FbAuth, code!);
      // Send back notification
      
      await RestPostVerification(idToken!);
      // Send verification email.
      enqueueSnackbar("Your account has been confirmed.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setVerified(true);
      setTimeout(() => {
        if (params.has("continue_url")) {
          navigate(params.get("continue_url") || "/login");
        }
      }, 5000);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError(true);
    }
    setBusy(false);
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Stack
      sx={{ minHeight: "100vh", transition: "all .2s", opacity: 1 }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack position={"relative"}>
        <img
          style={{
            top: "64px",
            left: "80px",
            pointerEvents: "none",
            height: "128px",
            margin: "48px",
          }}
          alt="Logo"
          src={LOGO}
        />
        <div
          style={{
            background: "#3CD7CD",
            height: "300px",
            width: "300px",
            position: "absolute",
            filter: "blur(220px)",
            zIndex: -1,
            bottom: 0,
            pointerEvents: "none",
          }}
        />
      </Stack>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderRadius: "38px",
          background:
            "linear-gradient(180deg, rgba(14, 11, 52, 0.1) 10%, rgba(60, 215, 205, 0.06) 100%)",
          border: "1px solid rgba(60, 215, 205, 0.2)",
          backdropFilter: "blur(10.5px)",
          px: "32px",
          py: "48px",
          marginBottom: "3rem"
        }}
      >
        {verified && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Badge color="primary" badgeContent={<Check />}>
                <Email sx={{ height: "64px", width: "64px" }} />
              </Badge>
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Email Verified
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                Thank you for confirming your email address. You can now log in.
              </Typography>
              <Button color="secondary" href="/login">
                Login
              </Button>
            </Stack>
          </>
        )}
        {!verified && busy && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Verifying Email...
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                This will only take a few seconds...
              </Typography>
              <Button color="secondary" href="/login">
                Login
              </Button>
            </Stack>
          </>
        )}
        {!verified && error && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Error
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                Unable to verify your email address. Please try again.
              </Typography>
              <Button color="secondary" href="/login">
                Login
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <GlobalFooter />
    </Stack>
  );
}
