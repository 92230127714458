// The file contains the Real-Time Messaging API functions.
import { RtmClient } from "@zexcore/rtm-client";
import { FbAuth } from "../firebase";
import { AppConfig } from "../../config";
import { User } from "../../types/User";
import { PlatformPricing } from "../../types/PlatformPricing";
import { PaginatedResponse } from "../../types/IPaginatedResponse";
import { Notification } from "../../types/Notification";

let Client: RtmClient;

export async function EnsureRtmConnection() {
  return new Promise(async (resolve, reject) => {
    if (!Client) {
      const token = await FbAuth.currentUser?.getIdToken();
      Client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: token,
        async onOpen() {
          console.log("Connected to the RTM server.");
          resolve(true);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export async function GetRtmClient() {
  await EnsureRtmConnection();
  return Client;
}

// All the common functions are defined here.

export async function RtmGetProfile() {
  await EnsureRtmConnection();
  try {
    const prof = await Client.CallWait<User>("RtmGetProfile");
    console.log({prof})
    return prof;
  } catch (error) {
    throw error
  }
}

export async function RtmSetProfile(profile: {
  image?: string;
  boarded?: boolean;
  firstName?: string;
  lastName?: string;
}) {
  await EnsureRtmConnection();
  const prof = await Client.CallWait<boolean>("RtmSetProfile", profile);
  return prof;
}

export async function RtmGetPricePlans() {
  await EnsureRtmConnection();
  const _result = await Client.CallWait<PlatformPricing[]>("RtmGetPricePlans");
  return _result;
}

/**
 * Clears all notifications.
 * @returns
 */
export async function RtmClearNotifications() {
  await EnsureRtmConnection();
  const _result = await Client.CallWait<boolean>("RtmClearNotifications");
  return _result;
}

export async function RtmGetNotifications(pageNumber: number) {
  await EnsureRtmConnection();
  const _result = await Client.CallWait<PaginatedResponse<Notification[]>>(
    "RtmGetNotifications",
    pageNumber
  );
  return _result;
}

export async function RtmGetUnreadNotificationsCount() {
  await EnsureRtmConnection();
  const _result = await Client.CallWait<number>(
    "RtmGetUnreadNotificationsCount"
  );
  return _result;
}
