import {
  Avatar,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NAV_LOGO } from "../assets";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FbAuth } from "../core/firebase";
import { useRecoilState } from "recoil";
import { aCurrentUser, aShowSidebar } from "../core/atoms";
import { Role } from "../types/Role";
import { useLocation, useNavigate } from "react-router";
import Ic_dashboard from "../assets/icons/ui/ic_dashboard";
import Ic_library from "../assets/icons/ui/ic_library";
import Ic_book from "../assets/icons/ui/ic_book";
import Ic_reports from "../assets/icons/ui/ic_reports";
import Ic_analytics from "../assets/icons/ui/ic_analytics";
import Ic_income from "../assets/icons/ui/ic_income";
import Ic_card from "../assets/icons/ui/ic_card";
import Ic_folders from "../assets/icons/ui/ic_folders";
import Ic_invoice from "../assets/icons/ui/ic_invoice";
import Ic_users from "../assets/icons/ui/ic_users";
import Ic_customers from "../assets/icons/ui/ic_customers";
import Ic_admin from "../assets/icons/ui/ic_admin";
import Ic_recent from "../assets/icons/ui/ic_recent";
import Ic_setting from "../assets/icons/ui/ic_setting";
import IC_NOTEBOOK from "../assets/icons/ui/IC_NOTEBOOK";
import IC_NEWS from "../assets/icons/ui/IC_NEWS";
import IC_DISCORD from "../assets/icons/ui/IC_DISCORD";
import { AppConfig } from "../config";
import { RtmGetDiscordMembership } from "../core/rtm/user";
import LoadingView from "./Loading";
import IC_USER_ADD from "../assets/icons/ui/IC_USER_ADD";

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);
  const [showAnalyticsMenu, setShowAnalyticsMenu] = useState(false);
  const [showPaymentsMenu, setShowPaymentsMenu] = useState(false);
  const [showIamMenu, setShowIamMenu] = useState(false);
  const [showLms, setShowLms] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const selected = location.pathname;

  const [user] = useRecoilState(aCurrentUser);
  const [discordMember, setDiscordMember] = useState<any>();

  async function loadMember() {
    try {
      const _data = await RtmGetDiscordMembership();
      setDiscordMember(_data);
    } catch (err: any) {
      console.error("Error loading discord membership data. ", err);
    }
  }

  // Returns the sidebar items based on the current user's role.
  function getSidebarContent() {
    if (user && user.role === Role.Administrator) {
      // Return admin sidebar content
      return (
        <List
          component={"nav"}
          sx={{
            "& .Mui-selected": {
              background: "#111 !important",
            },
          }}
        >
          <ListItemButton
            onClick={() => navigate("/dashboard/")}
            selected={selected === "/dashboard" || selected === "/dashboard/"}
          >
            <ListItemIcon>
              <Ic_dashboard width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
          <ListItemButton
            selected={selected == "/dashboard/lms/chapters"}
            onClick={() => navigate("/dashboard/lms/chapters")}
          >
            <ListItemIcon>
              <Ic_book width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Chapters</ListItemText>
          </ListItemButton>

          {/* <ListItemButton onClick={() => setShowLms(!showLms)}>
            <ListItemIcon>
              <Ic_library width={24} height={24} />
            </ListItemIcon>
            <ListItemText>LMS</ListItemText>
            {showLms ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showLms}>
            <List disablePadding>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected == "/dashboard/lms/chapters"}
                onClick={() => navigate("/dashboard/lms/chapters")}
              >
                <ListItemIcon>
                  <Ic_book width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Chapters</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/lms/fundamentals")}
                onClick={() => navigate("/dashboard/lms/fundamentals")}
              >
                <ListItemIcon>
                  <IC_NOTEBOOK width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Weekly Reports</ListItemText>
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/lms/news")}
                onClick={() => navigate("/dashboard/lms/news")}
              >
                <ListItemIcon>
                  <IC_NEWS width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Daily Events</ListItemText>
              </ListItemButton>
            </List>
          </Collapse> */}

          <ListItemButton
            onClick={() => setShowAnalyticsMenu(!showAnalyticsMenu)}
          >
            <ListItemIcon>
              <Ic_analytics width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Analytics</ListItemText>
            {showAnalyticsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showAnalyticsMenu}>
            <List disablePadding>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/analytics/sales")}
                onClick={() => navigate("/dashboard/analytics/sales")}
              >
                <ListItemIcon>
                  <Ic_income width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Sales</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/analytics/progress")}
                onClick={() => navigate("/dashboard/analytics/progress")}
              >
                <ListItemIcon>
                  <Ic_reports width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Progress Tracker</ListItemText>
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            onClick={() => setShowPaymentsMenu(!showPaymentsMenu)}
          >
            <ListItemIcon>
              <Ic_card width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Payments</ListItemText>
            {showPaymentsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showPaymentsMenu}>
            <List disablePadding>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/payments/invoices")}
                onClick={() => navigate("/dashboard/payments/invoices")}
              >
                <ListItemIcon>
                  <Ic_invoice width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Invoices</ListItemText>
              </ListItemButton>
              {/* <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/payments/plans")}
                onClick={() => navigate("/dashboard/payments/plans")}
              >
                <ListItemIcon>
                  <Ic_folders width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Manage Plans</ListItemText>
              </ListItemButton> */}
            </List>
          </Collapse>

          <ListItemButton onClick={() => setShowIamMenu(!showIamMenu)}>
            <ListItemIcon>
              <Ic_users width={24} height={24} />
            </ListItemIcon>
            <ListItemText>IAM</ListItemText>
            {showIamMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showIamMenu}>
            <List disablePadding>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/iam/users")}
                onClick={() => navigate("/dashboard/iam/users")}
              >
                <ListItemIcon>
                  <Ic_customers width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Users</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/iam/admins")}
                onClick={() => navigate("/dashboard/iam/admins")}
              >
                <ListItemIcon>
                  <Ic_admin width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Administrators</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/iam/affiliates")}
                onClick={() => navigate("/dashboard/iam/affiliates")}
              >
                <ListItemIcon>
                  <IC_USER_ADD width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Affiliates</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/iam/activity")}
                onClick={() => navigate("/dashboard/iam/activity")}
              >
                <ListItemIcon>
                  <Ic_recent width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Activity</ListItemText>
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setShowSettings(!showSettings)}>
            <ListItemIcon>
              <Ic_setting width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
            {showSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showSettings}>
            <List disablePadding>
              {/* <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/settings/platform")}
                onClick={() => navigate("/dashboard/settings/platform")}
              >
                <ListItemText>Configuration</ListItemText>
              </ListItemButton> */}
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/settings/discord")}
                onClick={() => navigate("/dashboard/settings/discord")}
              >
                <ListItemText>Discord</ListItemText>
              </ListItemButton>
              {/* <ListItemButton sx={{ pl: 10, opacity: 0.8 }} dense>
                <ListItemText>Athena™️</ListItemText>
              </ListItemButton> */}
            </List>
          </Collapse>
        </List>
      );
    } else if (user && user.role === Role.Moderator) {
      return (
        <List
          component={"nav"}
          sx={{
            "& .Mui-selected": {
              background: "#111 !important",
            },
          }}
        >
          <ListItemButton
            onClick={() => navigate("/dashboard/")}
            selected={selected === "/dashboard" || selected === "/dashboard/"}
          >
            <ListItemIcon>
              <Ic_dashboard width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>

          <ListItemButton onClick={() => setShowLms(!showLms)}>
            <ListItemIcon>
              <Ic_library width={24} height={24} />
            </ListItemIcon>
            <ListItemText>LMS</ListItemText>
            {showLms ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showLms}>
            <List disablePadding>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected == "/dashboard/lms/chapters"}
                onClick={() => navigate("/dashboard/lms/chapters")}
              >
                <ListItemIcon>
                  <Ic_book width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Chapters</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/lms/fundamentals")}
                onClick={() => navigate("/dashboard/lms/fundamentals")}
              >
                <ListItemIcon>
                  <IC_NOTEBOOK width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Weekly Reports</ListItemText>
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 10, opacity: 0.8 }}
                dense
                selected={selected.startsWith("/dashboard/lms/news")}
                onClick={() => navigate("/dashboard/lms/news")}
              >
                <ListItemIcon>
                  <IC_NEWS width={24} height={24} />
                </ListItemIcon>
                <ListItemText>Daily Events</ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      );
    } else if (user && user.role === Role.User) {
      // Return user sidebar content
      return (
        <List component={"nav"}>
          <ListItemButton
            selected={selected === "/dashboard"}
            onClick={() => navigate("/dashboard")}
          >
            <ListItemIcon>
              <Ic_dashboard width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>

          <ListItemButton
            selected={selected.startsWith("/dashboard/library")}
            onClick={() => navigate("/dashboard/library")}
          >
            <ListItemIcon>
              <Ic_library width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Library</ListItemText>
          </ListItemButton>
        </List>
      );
    } else {
      // Return loading indicator.
      return <></>;
    }
  }

  useEffect(() => {
    loadMember();
  }, []);

  return (
    <Stack
      sx={{
        maxWidth: "400px",
        background: "#0E0B3424",
        backdropFilter: "blur(12px)",
        [theme.breakpoints.down("md")]: {
          // display: !showSidebar ? "none" : undefined,
          position: "fixed",
          zIndex: 200,
          top: 0,
          bottom: 0,
          left: showSidebar ? 0 : -500,
          transition: "all .3s",
        },
      }}
    >
      {/* The logo  */}
      <img src={NAV_LOGO} style={{ height: "24px", margin: "24px" }} />
      <Divider />
      {getSidebarContent()}
      {!user && <LoadingView />}
      <Stack flex={1} />
      {user?.role === Role.User && !user.discord && (
        <Button
          href={AppConfig.discord.invite_link}
          startIcon={
            <SvgIcon>
              <IC_DISCORD />
            </SvgIcon>
          }
          variant="contained"
          sx={{
            mx: "24px",
            background: "#5865f2",
            ":hover": { background: "#5885f2" },
          }}
        >
          Connect Discord
        </Button>
      )}
      {user?.role === Role.User && user.emailVerified && user.discord && !discordMember && (
        <Button
          href={AppConfig.discord.invite_link}
          startIcon={
            <SvgIcon>
              <IC_DISCORD />
            </SvgIcon>
          }
          variant="contained"
          sx={{
            mx: "24px",
            background: "#5865f2",
            ":hover": { background: "#5885f2" },
          }}
        >
          Join our Server
        </Button>
      )}
      <Stack
        direction={"row"}
        sx={{ px: "24px", py: "12px" }}
        alignItems={"center"}
        spacing="12px"
      >
        <Avatar
          src={FbAuth.currentUser?.photoURL || ""}
          alt={FbAuth.currentUser?.displayName || ""}
        />
        <Stack>
          <Typography sx={{ fontSize: "18px", fontFamily: "Space Grotesk" }}>
            {FbAuth.currentUser?.displayName}
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {FbAuth.currentUser?.email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
