// import { useState, useRef } from "react";
// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// import { Button } from "@mui/material";

// const S3_BUCKET = "atu-staging";
// const REGION = "us-west-2";
// const ACCESS_KEY_ID = "AKIATDM5GRNVDSWIALXZ"; // Not secure for production
// const SECRET_ACCESS_KEY = "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"; // Not secure for production

// const s3Client = new S3Client({
//   region: REGION,
//   credentials: {
//     accessKeyId: ACCESS_KEY_ID,
//     secretAccessKey: SECRET_ACCESS_KEY,
//   },
// });

// function S3UploadWidget({ onUploadComplete }) {
//   const [uploading, setUploading] = useState(false);
//   const fileInputRef = useRef(null);

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     setUploading(true);

//     try {
//       const fileKey = `uploads/${Date.now()}-${file.name}`;

//       // ✅ Convert file to Blob for AWS SDK v3
//       const fileBlob = await file.arrayBuffer();

//       const uploadParams = {
//         Bucket: S3_BUCKET,
//         Key: fileKey,
//         Body: fileBlob, // ✅ Use arrayBuffer instead of file
//         ContentType: file.type,
//       };

//       await s3Client.send(new PutObjectCommand(uploadParams));
//       const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileKey}`;
//       console.log({ fileUrl });

//       onUploadComplete?.(fileUrl);
//     } catch (error) {
//       console.error("Upload failed:", error);
//     }

//     setUploading(false);
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         accept="video/*"
//         ref={fileInputRef}
//         onChange={handleFileChange}
//         disabled={uploading}
//         style={{ display: "none" }}
//       />
//       <Button
//         id="upload_widget"
//         className="cloudinary-button"
//         onClick={() => fileInputRef.current.click()}
//         disabled={uploading}
//         variant="contained"
//         color="primary"
//         sx={{
//             width: "100%"
//         }}
//       >
//         {uploading ? "Uploading video" : "Upload"}
//       </Button>
//     </div>
//   );
// }

// export default S3UploadWidget;

import { useState, useRef } from "react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Button } from "@mui/material";
import { AppConfig } from "../config";

const S3_BUCKET = AppConfig.s3_bucket;
const REGION = AppConfig.aws_region;
const ACCESS_KEY_ID = AppConfig.aws_access_key; // Not secure for production
const SECRET_ACCESS_KEY = AppConfig.aws_secret_key; // Not secure for production

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

function S3UploadWidget({ onUploadComplete }) {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);

    try {
      // ✅ Extract video duration
      const videoDuration = await getVideoDuration(file);
      console.log("Video Duration:", videoDuration, "seconds");

      const fileKey = `uploads/${Date.now()}-${file.name}`;

      // ✅ Convert file to Blob for AWS SDK v3
      const fileBlob = await file.arrayBuffer();

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: fileKey,
        Body: fileBlob, // ✅ Use arrayBuffer instead of file
        ContentType: file.type,
      };

      await s3Client.send(new PutObjectCommand(uploadParams));
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileKey}`;

      // ✅ Send video duration along with file URL
      onUploadComplete?.({ url, duration: videoDuration });
    } catch (error) {
      console.error("Upload failed:", error);
    }

    setUploading(false);
  };

  // ✅ Function to get video duration
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error("Error loading video metadata"));
      };

      video.src = URL.createObjectURL(file);
    });
  };

  return (
    <div>
      <input
        type="file"
        accept="video/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        disabled={uploading}
        style={{ display: "none" }}
      />
      <Button
        id="upload_widget"
        className="cloudinary-button"
        onClick={() => fileInputRef.current.click()}
        disabled={uploading}
        variant="contained"
        color="primary"
        sx={{
          width: "100%",
        }}
      >
        {uploading ? "Uploading video" : "Upload"}
      </Button>
    </div>
  );
}

export default S3UploadWidget;
