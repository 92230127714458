import { Stack, useTheme } from "@mui/material";
import StaticHeader from "./components/header";
import StaticHero from "./components/hero";
import StaticAbout from "./components/about";
import StaticFooter from "./components/footer";
import StaticPayouts from "./components/payouts";
import StaticTestimonials from "./components/testimonials";
import StaticDemo from "./components/demo";
import StaticBusinesses from "./components/businesses";
import { Helmet } from "react-helmet";
import GlobalFooter from "./components/global-footer";
const ogData = {
  title: "Alpha Trading University",
  description:
    "Join ATU, where you'll master fundamentals and psychology, analyze markets and charts on macro and micro levels and perfect your trading strategies.",
  url: "https://alphatradinguniversity.com",
  image: "https://alphatradinguniversity.com/brand/logo.png",
  siteName: "Alpha Trading University",
};
export default function PageLanding() {
  const theme = useTheme();
  return (
    <Stack
      spacing={"100px"}
      sx={{
        background: theme.palette.background.default,
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>{ogData.title}</title>
        <meta name="description" content={ogData.description} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
      </Helmet>
      <StaticHeader />
      <StaticHero />
      <StaticAbout />
      <StaticPayouts />
      <StaticTestimonials />
      <StaticDemo />
      {/* <StaticBusinesses /> */}
      <StaticFooter />
      <GlobalFooter />
    </Stack>
  );
}
