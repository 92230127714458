import {
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LOGO, NAV_LOGO } from "../../assets";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import ZInput from "../../components/ZInput";
import { Email, Password } from "@mui/icons-material";
import { RtmGetProfile } from "../../core/rtm";
import { Role } from "../../types/Role";
import { Helmet } from "react-helmet";
import GlobalFooter from "../landing/components/global-footer";

export default function PageLogin() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  async function signin(fe: any) {
    fe?.preventDefault?.();
    setError("");
    setBusy(false);
    // Make sure all fields are valid...
    if (!email) {
      setError("email");
      return;
    }
    if (!password) {
      setError("password");
      return;
    }
    try {
      setBusy(true);
      // Sign in using email and pass
      const r = await signInWithEmailAndPassword(FbAuth, email, password);
      if (r && !r.user.emailVerified) {
        // Don't login.
        enqueueSnackbar(
          "Please confirm your account by clicking the link sent to your email.",
          { variant: "warning" }
        );
      }
      navigate("/dashboard");
    } catch (err: any) {
      if (
        err.code &&
        (err.code === "auth/wrong-password" ||
          err.code === "auth/user-not-found" ||
          err.code === "auth/invalid-login-credentials")
      ) {
        enqueueSnackbar("Invalid username or password. Please try again.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error logging in. Please contact administrators.", {
          variant: "error",
        });
        console.log(err);
      }
    }
    setBusy(false);
  }

  async function load() {
    // if (FbAuth.currentUser) {
    //   const _profile = await RtmGetProfile();
    //   if (
    //     _profile &&
    //     (_profile.role === Role.Administrator ||
    //       _profile.role === Role.Moderator)
    //   ) {
    //     navigate("/dashboard");
    //   } else if (_profile && _profile.boarded && _profile.subscription) {
    //     // to dashboard
    //     navigate("/dashboard");
    //   } else if (_profile && _profile.subscription && !_profile.boarded) {
    //     // to onboarding
    //     navigate("/onboarding");
    //   } else if (_profile && !_profile.subscription && !_profile.boarded) {
    //     // to join
    //     navigate("/join");
    //   } else if (_profile && _profile.boarded) {
    //     // to dashboard, but will have restricted controls due to expired/non-existing subscription
    //     navigate("/dashboard");
    //   }
    // }
    if (FbAuth.currentUser) {
      const _profile = await RtmGetProfile();
      if (
        _profile &&
        (_profile.role === Role.Administrator ||
          _profile.role === Role.Moderator)
      ) {
        navigate("/dashboard");
      } else if (_profile && _profile.boarded) {
        // to dashboard
        navigate("/dashboard");
      } else if (_profile && !_profile.boarded) {
        // to onboarding
        navigate("/onboarding");
      }
    }
    console.log("Not logged in.");
  }

  useEffect(() => {
    return FbAuth.onAuthStateChanged((state) => {
      load();
    });
  }, []);

  return (
    <Stack
      sx={{
        // minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        // minWidth: "100vw",
      }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Helmet>
        <title>Sign In - Alpha Trading University</title>
      </Helmet>
      <Stack position={"relative"}>
        <img
          style={{
            top: "64px",
            left: "80px",
            pointerEvents: "none",
            height: isDesktop ? "128px" : "64px",
            margin: "48px",
          }}
          alt="Logo"
          src={LOGO}
        />
        <div
          style={{
            background: "#3CD7CD",
            height: "300px",
            width: "300px",
            position: "absolute",
            filter: "blur(220px)",
            zIndex: -1,
            bottom: 0,
            pointerEvents: "none",
          }}
        />
      </Stack>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderRadius: "38px",
          background:
            "linear-gradient(180deg, rgba(14, 11, 52, 0.1) 10%, rgba(60, 215, 205, 0.06) 100%)",
          border: "1px solid rgba(60, 215, 205, 0.2)",
          backdropFilter: "blur(10.5px)",
          px: "32px",
          py: "48px",
          alignSelf: "center",
          marginBottom: "3rem"
        }}
      >
        <Stack
          alignItems={"center"}
          sx={{ mt: "32px", [theme.breakpoints.down("md")]: { mt: 0 } }}
          spacing="12px"
        >
          <Typography
            fontSize={"30px"}
            fontWeight={"600"}
            sx={{
              color: "#F5F5F6",
              fontFamily: "Space Grotesk",
              [theme.breakpoints.down("md")]: {
                fontSize: 24,
              },
            }}
          >
            Sign in to your account
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#94969C",
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            Enter your details to sign in
          </Typography>
        </Stack>
        <FormGroup onSubmit={signin}>
          <Stack
            spacing="20px"
            sx={{
              mt: "32px",
              width: "360px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >
            <ZInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email "
              startIcon={<Email />}
              errorText={error === "email" ? "Invalid email address." : ""}
            />
            <ZInput
              password
              text={password}
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              placeholder="Enter your password"
              startIcon={<Password />}
              errorText={error === "password" ? "Invalid password." : ""}
              onReturn={signin}
            />
            <Stack spacing={"2px"}>
              <Link href="/recovery" underline="none" color={"secondary"}>
                Forgot your password?
              </Link>
            </Stack>
            <Button
              onClick={signin}
              disabled={busy}
              size="large"
              type="submit"
              sx={{ mt: "4px", height: "42px" }}
              variant="contained"
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Sign in"}
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Don't have an account?
              </Typography>
              <Link
                fontSize={"14px"}
                href="/join"
                underline="none"
                color={"secondary"}
              >
                Sign up
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
      <GlobalFooter />
    </Stack>
  );
}
