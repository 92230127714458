import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const ImageEnlarger = ({ src, alt, onClose }: {src: string, alt: string, onClose: () => void}) => {

  return (
    <div>
      {/* Clickable Image */}

      {/* Fullscreen Image View with Backdrop */}
      {src && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "#0005",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            backdropFilter: "blur(10px)"
          }}
          onClick={onClose} // Close when clicking outside the image
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "white",
              fontSize: "2rem",
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </button>

          {/* Enlarged Image */}
          <img
            src={src}
            alt={alt}
            style={{
              width: "95vw",
              height: "95vh",
              objectFit: "contain",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageEnlarger;
