// import {
//   Button,
//   Divider,
//   FormControlLabel,
//   LinearProgress,
//   Radio,
//   RadioGroup,
//   Stack,
//   SxProps,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import { Quiz } from "../types/Quiz";
// import { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import { shuffleArray } from "../core/helper";
// /**
//  * Shows a quiz to the user.
//  */
// export default function QuizView(props: {
//   quiz?: Quiz;
//   onComplete?: (result: "passed" | "failed", progress: Quiz) => void;
//   onDismiss?: () => void;
//   sx?: SxProps;
// }) {
//   const theme = useTheme();
//   const quiz = props.quiz;
//   const [questionNumber, setQuestionNumber] = useState(0);
//   const [selectedAnswer, setSelectedAnswer] = useState(0);
//   const [quizProgress, setQuizProgress] = useState<Quiz>();
//   const [finished, setFinished] = useState(false);
//   const [result, setResult] = useState<{
//     pass: boolean;
//     score: number;

//     correct: number;
//     total: number;
//   }>();
//   const [choices, setChoices] = useState<string[]>([]);

//   function saveAnswer() {
//     const selectedAnswerText = choices[selectedAnswer];
//     const prog = quizProgress || quiz;
//     prog!.questions[questionNumber]!.selectedChoice = selectedAnswerText!;
//     setQuizProgress(prog);

//     // if no more questions, we show finished screen.
//     if (questionNumber < quiz!.questions.length - 1) {
//       // bring next question
//       setQuestionNumber(questionNumber + 1);
//     } else {
//       // Finish
//       setFinished(true);
//       // Calculate results.
//       let _correct = 0;
//       for (let q of prog?.questions!) {
//         if (q.selectedChoice === q.choices[q.answer]) {
//           // correct
//           _correct += 1;
//         }
//       }
//       // score
//       const _score = (_correct / prog?.questions.length!) * 100;
//       setResult({
//         pass: _score > 80,
//         score: _score,
//         correct: _correct,
//         total: prog?.questions.length!,
//       });
//     }
//   }

//   useEffect(() => {
//     const _choices = quiz?.questions[questionNumber].choices!.slice(0);
//     shuffleArray(_choices!);
//     setChoices(_choices!);
//     setSelectedAnswer(-1);
//   }, [questionNumber]);

//   if (!props.quiz) return <></>;

//   if (result && !result.pass) {
//     return (
//       <Stack
//         sx={{
//           position: "fixed",
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           width: "100%",
//           background: "#0005",
//           zIndex: 100,
//           backdropFilter: "blur(10px)",
//         }}
//         alignItems={"center"}
//         justifyContent={"center"}
//         spacing={"14px"}
//       >
//         <Typography>Score: {Math.floor(result.score)}%</Typography>
//         <Stack alignItems={"center"}>
//           <Typography fontSize={32} fontFamily={"Space Grotesk"}>
//             Quiz Failed!
//           </Typography>
//           <Typography>
//             You didn't pass the quiz. You can retake it once you reach the end
//             of the lecture.
//           </Typography>
//           <Typography fontWeight={500}>
//             {result.correct} out of {result.total} questions were correct.
//           </Typography>
//         </Stack>
//         {
//           quiz?.questions.map((q, index) => {
//             return (
//               <Stack alignItems={"center"}>
//                 <Typography>
//                   Question - {index} {q.question} 
//                 </Typography>
//                 <Typography sx={{color: "lightgreen"}}>
//                   Answer - {q.choices[q.answer]}
//                 </Typography>
//               </Stack>
//             );
//           })
//         }
//         <Button onClick={() => props.onComplete?.("failed", quizProgress!)}>
//           Back to Video
//         </Button>
//       </Stack>
//     );
//   }

//   if (result && result.pass) {
//     return (
//       <Stack
//         sx={{
//           position: "fixed",
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           width: "100%",
//           background: "#0005",
//           zIndex: 100,
//           backdropFilter: "blur(10px)",
//         }}
//         alignItems={"center"}
//         justifyContent={"center"}
//         spacing={"14px"}
//       >
//         <Typography>Score: {Math.floor(result.score)}%</Typography>
//         <Stack alignItems={"center"}>
//           <Typography fontSize={32} fontFamily={"Space Grotesk"}>
//             You passed the quiz!
//           </Typography>
//           <Typography>You can continue watching the next lesson.</Typography>
//           <Typography fontWeight={500}>
//             {result.correct} out of {result.total} questions were correct.
//           </Typography>
//         </Stack>
//         <Button
//           variant="contained"
//           onClick={() => props.onComplete?.("passed", quizProgress!)}
//         >
//           Next Lesson
//         </Button>
//       </Stack>
//     );
//   }

//   return (
//     // <motion.div
//     //   style={{
//     //     position: "fixed",
//     //     top: 0,
//     //     left: 0,
//     //     right: 0,
//     //     bottom: 0,
//     //     width: "100%",
//     //     background: "#0005",
//     //     zIndex: 100,
//     //   }}
//     //   initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
//     //   whileInView={{ opacity: 1, backdropFilter: "blur(10px)" }}
//     //   transition={{ duration: 1 }}
//     // >
//       <Stack
//         sx={{
//           height: "100%",
//         }}
//       >
//         <Stack flex={1} sx={{ px: "24px", py: "24px" }}>
//           <Typography fontSize={22} fontFamily={"Space Grotesk"}>
//             Quiz
//           </Typography>
//           <Typography>Complete the quiz to unlock next lesson.</Typography>
//           <Stack flex={1} justifyContent={"center"}>
//             <Typography>Question # {questionNumber + 1}</Typography>
//             <Typography
//               fontSize={32}
//               sx={{
//                 [theme.breakpoints.down("md")]: {
//                   fontSize: 22,
//                 },
//               }}
//             >
//               {quiz!.questions[questionNumber].question}
//             </Typography>
//             <Divider />
//             <RadioGroup
//               name="quiz"
//               sx={{
//                 mt: "48px",
//                 ml: "48px",
//                 [theme.breakpoints.down("md")]: {
//                   ml: "24px",
//                 },
//               }}
//             >
//               {choices.map((c, i) => (
//                 <FormControlLabel
//                   control={
//                     <Radio
//                       radioGroup="quiz"
//                       checked={selectedAnswer === i}
//                       onChange={(e) => {
//                         if (e.target.checked) {
//                           setSelectedAnswer(i);
//                         }
//                       }}
//                     />
//                   }
//                   label={c}
//                 />
//               ))}
//             </RadioGroup>

//             <Button
//               onClick={saveAnswer}
//               sx={{
//                 alignSelf: "flex-start",
//                 mt: "48px",
//                 ml: "48px",
//                 [theme.breakpoints.down("md")]: {
//                   ml: "24px",
//                 },
//               }}
//               variant="contained"
//             >
//               Next
//             </Button>
//           </Stack>
//         </Stack>
//         <LinearProgress
//           variant="buffer"
//           valueBuffer={(questionNumber / quiz?.questions.length!) * 100}
//           value={0}
//         />
//       </Stack>
//     // </motion.div>
//   );
// }

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Quiz } from "../types/Quiz";
import { useEffect, useState } from "react";
import { shuffleArray } from "../core/helper";
import { motion } from "framer-motion";
export default function QuizView(props: {
  quiz?: Quiz;
  onComplete?: (result: "passed" | "failed", progress: Quiz) => void;
  onDismiss?: () => void;
  onReviewMaterial?: () => void; // ✅ New prop to review learning material
  sx?: SxProps;
  onClose?: () => void;
}) {
  const theme = useTheme();
  const quiz = props.quiz;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [quizProgress, setQuizProgress] = useState<Quiz>();
  const [finished, setFinished] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState<number[]>([]);
  const [choices, setChoices] = useState<string[]>([]);

  function saveAnswer() {
    if (selectedAnswer === null) return; // Prevent empty submissions

    const selectedAnswerText = choices[selectedAnswer];
    const prog = quizProgress || { ...quiz, questions: quiz?.questions || [] };

    // const prog = quizProgress || { ...quiz }; // Clone quiz object
    prog!.questions[questionNumber]!.selectedChoice = selectedAnswerText!;
    setQuizProgress(prog);

    // Track incorrect questions
    if (
      selectedAnswerText !==
      prog.questions[questionNumber].choices[
        prog.questions[questionNumber].answer
      ]
    ) {
      setIncorrectQuestions((prev) => [...prev, questionNumber]);
    }

    if (questionNumber < quiz!.questions.length - 1) {
      setQuestionNumber(questionNumber + 1);
    } else {
      setFinished(true);
    }
  }

  function retryIncorrect() {
    if (incorrectQuestions.length > 0) {
      setQuestionNumber(incorrectQuestions[0]); // Start with the first incorrect question
      setIncorrectQuestions([]); // Reset incorrect tracking
      setFinished(false);
    } else {
      props.onComplete?.("failed", quizProgress!);
    }
  }

  useEffect(() => {
    const _choices = quiz?.questions[questionNumber].choices!.slice(0);
    shuffleArray(_choices!);
    setChoices(_choices!);
    setSelectedAnswer(null);
  }, [questionNumber]);

  if (!props.quiz) return <></>;

  if (finished) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ p: 4 }}
      >
        <Typography fontSize={22}>Quiz Completed!</Typography>
        <Typography>
          {incorrectQuestions.length > 0
            ? "You got some questions wrong. Try again!"
            : "You passed the quiz!"}
        </Typography>
        <Typography>
          Correct Answers: {quiz!.questions.length - incorrectQuestions.length}{" "}
          / {quiz!.questions.length}
        </Typography>

        {incorrectQuestions.length > 0 ? (
          <Button
          sx={{width: "20rem"}} variant="contained" onClick={retryIncorrect}>
            Retry Incorrect Questions
          </Button>
        ) : (
          <Button
          sx={{width: "10rem"}}
            variant="contained"
            onClick={() => props.onComplete?.("passed", quizProgress!)}
          >
            Next Lesson
          </Button>
        )}
        <Button onClick={props.onClose} sx={{ mt: 2, width: "10rem" }} variant="contained">
          Exit Quiz
        </Button>
      </Stack>
    );
  }

  return (
    // <motion.div
    //   style={{
    //     position: "fixed",
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     width: "100%",
    //     background: "#0005",
    //     zIndex: 100,
    //     display: "flex", // Center the modal
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    //   initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
    //   whileInView={{ opacity: 100, backdropFilter: "blur(10px)" }}
    //   transition={{ duration: 1 }}
    // >
    
        <Stack sx={{ height: "100%", p: 4 }}>
          <Stack flex={1}>
            <Typography fontSize={22}>Quiz</Typography>
            <Typography>
              Complete the quiz to unlock the next lesson.
            </Typography>

            <Stack flex={1} justifyContent="center">
              <Typography>Question # {questionNumber + 1}</Typography>
              <Typography
                fontSize={32}
                sx={{ [theme.breakpoints.down("md")]: { fontSize: 22 } }}
              >
                {quiz!.questions[questionNumber].question}
              </Typography>
              <Divider />

              <RadioGroup sx={{ mt: "24px" }}>
                {choices.map((c, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Radio
                        checked={selectedAnswer === i}
                        onChange={() => setSelectedAnswer(i)}
                      />
                    }
                    label={c}
                  />
                ))}
              </RadioGroup>

              <Button
                onClick={saveAnswer}
                sx={{ mt: "24px", width: "10rem" }}
                variant="contained"
              >
                {questionNumber < quiz!.questions.length - 1
                  ? "Next"
                  : "Submit"}
              </Button>
            </Stack>
          </Stack>


          <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          sx={{ mt: 2 }}
        >
          <Button onClick={props.onClose} variant="contained" sx={{width: "10rem"}}>
            Exit Quiz
          </Button>
        </Stack>
          <LinearProgress
            variant="buffer"
            valueBuffer={(questionNumber / quiz?.questions.length!) * 100}
            value={0}
            sx={{marginTop: "1rem"}}
          />
        </Stack>
    // {/* </motion.div> */}
  );
}

