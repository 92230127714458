import {
  Button,
  Chip,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NAV_LOGO } from "../../assets";
import ZInput from "../../components/ZInput";
import ZCheckbox from "../../components/ZCheckBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  CheckCircle,
  Code,
  Email,
  Password,
  Person,
} from "@mui/icons-material";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import { enqueueSnackbar } from "notistack";
import { RestInitiateCheckout, RestPostSignup } from "../../core/rest";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  getCookie,
  getCurrencySymbol,
  parseStripeAmount,
  setCookie,
} from "../../core/helper";
import { PlatformPricing } from "../../types/PlatformPricing";
import { AppConfig } from "../../config";
import { RtmGetProfile } from "../../core/rtm";
import { Role } from "../../types/Role";
import { Helmet } from "react-helmet";
import GlobalFooter from "../landing/components/global-footer";
export default function PageSignup(props: { plan: PlatformPricing }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loc = useLocation(); // We get the referral code and save it in storage in case user does not type it.
  const parms = new URLSearchParams(loc.search);
  const _referrerCode = parms.get("ref")?.toString() || getCookie("ref");
  if (_referrerCode) {
    // We save it in cookies/storage
    setCookie("ref", _referrerCode, 7);
  }
  const [referralCode, setReferralCode] = useState(_referrerCode);
  const [checkoutError, setCheckoutError] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  /**
   * Called upon form submission.
   * @param fe
   * @returns
   */
  async function getStarted(fe: any) {
    fe?.preventDefault?.();
    setError("");

    // If we are signing up, we make sure all fields are valid...
    if (!FbAuth.currentUser) {
      if (!firstName || !lastName) {
        setError("name");
        return;
      }
      if (!email) {
        setError("email");
        return;
      }
      if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
        setError("password");
        return;
      }
    }
    const err = await elements?.submit();
    if (err?.error) {
      return;
    }
    setBusy(true);
    // If not logged in, we sign up
    if (!FbAuth.currentUser) {
      const _signedUp = await signup();
      if (!_signedUp) {
        setBusy(false);
        return;
      }
    }

    // await checkout();

    setBusy(false);
  }

  /**
   * Creates a new user account on Firebase. Occurs only when the user is not already signed up (e.g. when trying payment again.)
   */
  async function signup() {
    try {
      setBusy(true);
      // Create account on firebase.
      const acc = await createUserWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        // Signed up. We set the user's name.
        await updateProfile(acc.user, {
          displayName: firstName + " " + lastName,
        });
        // We create the local user on backend, which also sends confirmation email.
        await RestPostSignup(
          firstName,
          lastName,
          referralCode || _referrerCode
        );
        setCreated(true);
        return true;
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/weak-password") {
          enqueueSnackbar("Password must be atleast 6 characters long.", {
            variant: "error",
          });
          return false;
        } else if (err.code === "auth/email-already-in-use") {
          // Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
          try {
            const creds = await signInWithEmailAndPassword(
              FbAuth,
              email,
              password
            );
            if (creds && creds.user.emailVerified) {
              return true;
            } else if (creds && !creds.user.emailVerified) {
              // Send verification email
              await RestPostSignup(
                firstName,
                lastName,
                referralCode || _referrerCode
              );
              setCreated(true);
            }
          } catch (err: any) {
            // Invalid password?
            signOut(FbAuth);
            if (err.code && err.code === "auth/internal-error") {
              enqueueSnackbar("Too many attempts, please try again later. ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(
                "Error signing up. Please try again in a few minutes.",
                { variant: "error" }
              );
            }
          }
        } else if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many tries. Please try again later. ", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            "Error signing up. Please try again or contact administrators.",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(
          "Unknown error signing up. Please try again or contact administrators.",
          { variant: "error" }
        );
      }
    }
    setBusy(false);
    return false;
  }

  /**
   * Checks out the currently logged in user.
   */
  async function checkout() {
    try {
      if (!FbAuth.currentUser && !created) {
        throw new Error("User is not logged in.");
      }
      setBusy(true);
      // Create subscription
      const sub = await RestInitiateCheckout(
        props.plan.name,
        FbAuth.currentUser!.email!,
        FbAuth.currentUser!.displayName!,
        FbAuth.currentUser!.uid
      );
      const confirmIntent =
        sub.type === "setup" ? stripe?.confirmSetup : stripe?.confirmPayment;

      const res = await confirmIntent!({
        clientSecret: sub.clientSecret!,
        elements: elements!,
        confirmParams: {
          return_url: AppConfig.stripe.returnUrl,
        },
      });
      if (
        res?.error.type === "card_error" ||
        res?.error.type === "validation_error"
      ) {
        setCheckoutError(res?.error?.message || "");
        enqueueSnackbar(res.error.message, { variant: "error" });
      } else {
        setCheckoutError("An unexpected error occurred.");
        enqueueSnackbar("Unknown error during checkout. ", {
          variant: "error",
        });
      }
      setError("checkout");
    } catch (err: any) {
      enqueueSnackbar("Error checking out. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  function RenderPrice(price: PlatformPricing) {
    let _priceInfo = parseStripeAmount(price.amount || 0);
    let _schedule = "lifetime";
    if (price.kind === "recurring") {
      switch (price.schedule) {
        case "3-month":
          _schedule = "every 3 months";
          break;
        case "daily":
          _schedule = "daily";
          break;
        case "6-month":
          _schedule = "every 6 months";
          break;
        case "monthly":
          _schedule = "monthly";
          break;
        case "weekly":
          _schedule = "weekly";
          break;
        case "yearly":
          _schedule = "yearly";
          break;
      }
    }
    const _price = `${getCurrencySymbol(price.currency || "usd")}${
      _priceInfo.dollars
    }.${_priceInfo.cents}`;
    return (
      <Stack
        flex={1}
        sx={{
          p: "24px",
          borderRadius: "8px",
          background: "#2222",
          border: "1px solid #333",
          borderColor: theme.palette.secondary.main,
          position: "relative",
        }}
        spacing="8px"
      >
        <CheckCircle
          color="secondary"
          sx={{ position: "absolute", top: "12px", right: "12px" }}
        />
        <Stack>
          <Typography fontSize={22} fontFamily={"Space Grotesk"}>
            {price.name}
          </Typography>
          <Typography>{price.description}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {_price}
          </Typography>
          <Chip
            label={_schedule}
            sx={{ textTransform: "capitalize" }}
            size="small"
          />
        </Stack>
        {Boolean(price.trial) && price.trial! > 0 && (
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography fontSize={12}>Trial Period:</Typography>
            <Chip
              label={price.trial + " days"}
              sx={{ textTransform: "capitalize" }}
              size="small"
            />
          </Stack>
        )}
      </Stack>
    );
  }

  // Renders user profile, when they are already logged in, along with signout button.
  function RenderUser() {
    return (
      <Stack>
        <Typography
          fontSize={"30px"}
          fontWeight={"600"}
          sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
        >
          Hello, {FbAuth.currentUser?.displayName || firstName + " " + lastName}
          !
        </Typography>

        <Typography sx={{ fontSize: "16px", opacity: 0.8 }}>
          Ready to join the ATU? Enter your billing information to get started!
        </Typography>
        <Stack
          justifyContent={"flex-start"}
          direction={"row"}
          alignItems={"flex-start"}
          sx={{ mt: "48px" }}
          spacing="8px"
        >
          <Typography color="#94969C" fontSize={"14px"}>
            Not {FbAuth.currentUser?.displayName}?
          </Typography>
          <Link
            onClick={() => signOut(FbAuth)}
            underline="none"
            color="secondary"
            fontSize={"14px"}
          >
            Logout
          </Link>
        </Stack>
      </Stack>
    );
  }

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  useEffect(() => {
    return FbAuth.onAuthStateChanged(async (state) => {
      if (state) {
        setIsLoggedIn(true);
        // const _profile = await RtmGetProfile();
        // if (
        //   _profile &&
        //   (_profile.subscription || _profile.role !== Role.User) &&
        //   !created
        // ) {
        //   // Redirect to dashboard.
        //   navigate("/dashboard");
        // }
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  return (
    <Stack
      p={"24px"}
      sx={{ position: "relative", overflow: "hidden" }}
      alignItems={"center"}
    >
      <Helmet>
        <title>Join Alpha Trading University</title>
      </Helmet>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
        }}
      >
        <img src={NAV_LOGO} alt="Alpha Trading University Logo" />
      </Stack>

      <Stack
        flex={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        direction={"column"}
        sx={{
          marginBottom: "3rem",
          maxWidth: "1320px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "start",
          },
        }}
      >
        <div
          style={{
            background: "#3CD7CD",
            height: "500px",
            width: "500px",
            position: "absolute",
            filter: "blur(350px)",
            left: -400,
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        {isLoggedIn && <RenderUser />}
        {!isLoggedIn && (
          <Stack sx={{ mt: "32px" }} spacing="12px">
            <Stack
              justifyContent={"flex-start"}
              direction={"row"}
              alignItems={"flex-start"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Already have an account?
              </Typography>
              <Link
                href="/login"
                underline="none"
                color="secondary"
                fontSize={"14px"}
              >
                Login
              </Link>
            </Stack>
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{
                color: "#F5F5F6",
                fontFamily: "Space Grotesk",
                [theme.breakpoints.down("md")]: {
                  fontSize: 24,
                },
              }}
            >
              Create Your Account
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                opacity: 0.8,
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              Ready to join the ATU? Enter your information below to get
              started.
            </Typography>
            <FormGroup>
              <Stack
                spacing="20px"
                sx={{
                  mt: "32px",
                  width: "460px",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
              >
                <Stack direction={"row"} spacing={"12px"}>
                  <ZInput
                    text={firstName}
                    label="First Name"
                    errorText={
                      error === "name" ? "Please enter your name." : ""
                    }
                    onUpdate={(t) => setFirstName(t)}
                    important
                    disabled={busy}
                    placeholder="First name"
                    startIcon={<Person />}
                  />
                  <ZInput
                    text={lastName}
                    label="Last Name"
                    errorText={
                      error === "name" ? "Please enter your name." : ""
                    }
                    onUpdate={(t) => setLastName(t)}
                    important
                    disabled={busy}
                    placeholder="Last name"
                  />
                </Stack>

                <ZInput
                  text={email}
                  errorText={
                    error === "email" ? "Please enter your email." : ""
                  }
                  onUpdate={(t) => setEmail(t)}
                  label="Email"
                  important
                  disabled={busy}
                  placeholder="someone@example.com"
                  startIcon={<Email />}
                />

                {/* <ZInput
                  text={referralCode}
                  disabled={busy}
                  onUpdate={(t) => setReferralCode(t)}
                  label="Invite code"
                  placeholder="Have an invite code? Enter it here."
                  startIcon={<Code />}
                /> */}
                <ZInput
                  text={password}
                  errorText={
                    error === "password" ? "Please enter a valid password." : ""
                  }
                  onUpdate={(t) => setPassword(t)}
                  label="Password"
                  disabled={busy}
                  important
                  password
                  placeholder="Create a password"
                  startIcon={<Password />}
                  onReturn={getStarted}
                />
                <Button
                  size="large"
                  type="submit"
                  id="submit"
                  disabled={busy}
                  sx={{ mt: "4px", height: "42px" }}
                  variant="contained"
                  onClick={getStarted}
                >
                  {busy && (
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                      style={{ height: "100px" }}
                    />
                  )}
                  {!busy && "Sign up"}
                </Button>
                <Stack spacing={"2px"}>
                  <ZCheckbox
                    value={passwordHasEnoughCharacters}
                    label="Must be at least 8 characters"
                  />
                  <ZCheckbox
                    value={passwordHasSymbol}
                    label="Must contain one special character"
                  />
                </Stack>
              </Stack>
            </FormGroup>
          </Stack>
        )}

        {/* {isLoggedIn && (
          <Stack spacing={"18px"}>
            <Stack spacing="8px">
              <Typography>Choose a Plan</Typography>
              <Stack direction={"row"} spacing={"12px"}>
                {RenderPrice(props.plan)}
              </Stack>
            </Stack>

            <PaymentElement />

            {error === "checkout" && (
              <Typography color={"error"}>
                {checkoutError ||
                  "An error occured during checkout. Please try again."}
              </Typography>
            )}

            <Button
              size="large"
              type="submit"
              id="submit"
              disabled={busy}
              sx={{ mt: "4px", height: "42px" }}
              variant="contained"
              onClick={getStarted}
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Get started"}
            </Button>
            <div
              style={{
                background: "#3CD7CD",
                height: "500px",
                width: "500px",
                position: "absolute",
                filter: "blur(350px)",
                zIndex: -1,
                pointerEvents: "none",
              }}
            />
          </Stack>
        )} */}
      </Stack>
      <GlobalFooter />
    </Stack>
  );
}
