// The file contains the HTTP-API endpoints of the application backend.

import { AppConfig } from "../../config";
import { PlatformPricing } from "../../types/PlatformPricing";
import { GetCurrentToken } from "../firebase";
import axios from "axios";
const BASE_URL = AppConfig.api;

/**
 * Called after a successful signup event. Sends an account confirmation email for the logged in user.
 */
export async function RestPostSignup(
  firstName: string,
  lastName: string,
  ref?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  await axios.post(
    BASE_URL + "/accounts/signup",
    { ref, firstName, lastName },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

/**
 * Called after a successful verification event. Sends a welcome email for the registered user.
 */
export async function RestPostVerification(token: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/confirmed", {
    token: token,
  });
}
export async function RestPasswordReset(token: string, password: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/reset-password", {
    token: token,
    password
  });
}

/**
 * Sends a password reset link via email. Called from forgot password section.
 */
export async function RestRequestPasswordReset(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/recover", {
    email: email,
  });
}

/**
 * Creates a new checkout session for payment page.
 */
export async function RestInitiateCheckout(
  packageId: string,
  email: string,
  name: string,
  uid: string
) {
  // Send the request
  const r = await axios.post(BASE_URL + "/payments/initiate", {
    package: packageId,
    email: email,
    name: name,
    uid: uid,
  });
  return r.data as { type: "setup" | "payment"; clientSecret: string };
}

export async function RestCheckoutStatus(sessionId: string) {
  // Send the request
  const r = await axios.get(
    BASE_URL + "/payments/status?session_id" + sessionId
  );
  return r.data as { status: string; customer_email: string };
}

/**
 * Returns the current price plans of platform.
 */
export async function RestGetPricePlans() {
  // Send the request
  const r = await axios.get(BASE_URL + "/payments/plans");
  return r.data as PlatformPricing[];
}
