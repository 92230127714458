import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "../config";
import { useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { PlatformPricing } from "../types/PlatformPricing";
import { RestGetPricePlans } from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Elements } from "@stripe/react-stripe-js";
import PageSignup from "../pages/join";
const stripePromise = loadStripe(AppConfig.stripe.publishKey);

export default function CheckoutContainer(props: any) {
  const theme = useTheme();

  const [plans, setPlans] = useState<PlatformPricing[]>([]);

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "8px",
      colorBackground: "#333",
      colorPrimary: "#FFF",
      colorSecondary: "#DDD",
      colorText: "#FFF",
      logoColor: "#FFF",
      tabLogoColor: "#FFF",
      blockLogoColor: "#FFF",
      tabLogoSelectedColor: "#FFF",
      accessibleColorOnColorPrimary: "#262626",
      accessibleColorOnColorBackground: "#333",
      colorTextSecondary: "#DDD",
      iconColor: "#FFF",
      tabIconSelectedColor: "#FFF",
    },
    rules: {
      ".Block": {
        backgroundColor: "#ffffff16",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
        backgroundColor: "#ffffff16",
        color: "#FFF",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Input:focus": {
        border: "0px solid #FFF",
        color: "#DDD",
        backgroundColor: "#333",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "0px solid #333",
        color: "#AAA",
        backgroundColor: "#ffffff16",
      },
      ".Tab:hover": {
        border: "0px solid #333",
        backgroundColor: "#333",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        color: "#FFF",
        logoColor: "#FFF",
        backgroundColor: theme.palette.primary.light,
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
        color: theme.palette.text.primary,
        fontFamily: "Helvetica",
        fontSize: 14,
      },
    },
  };

  async function load() {
    try {
      // Load price plans.
      const _plans = await RestGetPricePlans();
      if (_plans) {
        setPlans(_plans);
      }
    } catch (err: any) {
      console.error("Error initiating checkout. ", err);
      enqueueSnackbar("Error loading checkout elements. ", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    load();
  }, []);

  if (plans.length <= 0) return <></>;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        amount: plans[0]?.amount || 0,
        currency: plans[0]?.currency || "",
        appearance: appearance as any,
        mode: plans[0]?.kind === "lifetime" ? "payment" : "subscription",
      }}
    >
      <props.form plan={plans[0]!} />
    </Elements>
  );
}
