// The file provides configuration options to the application, loaded via environment variables.
// For debug mode (e.g. when the project is started with npm start command, the debug configuration is loaded).
// This is done by using REACT_APP_ATU_DEBUG flag.

// testing changes

const debug = {
  debug: true,
  api: `${process.env.REACT_APP_API_URL}`,
  rtm: `${process.env.REACT_APP_SOCKET_URL}`,
  // api: "http://localhost:3001/api",
  // rtm: "ws://localhost:3001/api",
  firebase: {
    apiKey: "AIzaSyCCs81h4-avdPrQNz5Oy4ttKFBJBWCPddA",
    authDomain: "alphatradinguniversity.firebaseapp.com",
    projectId: "alphatradinguniversity",
    storageBucket: "alphatradinguniversity.appspot.com",
    messagingSenderId: "268950678737",
    appId: "1:268950678737:web:7c47e047eb7e54f324e9f0",
  },
  loadingAnimation:
    "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
  stripe: {
    publishKey:
      "pk_test_51OHqsQIsr3YIbvSKzv2I3o9JimiT0AravE5vzkLFWXHjjzRw1IUtnC69jxHat1x6TfVvmxU6tMx7Q2i8xi9pozkU00wv1d4MkC",
    returnUrl: "http://localhost:3000/checkout",
  },
  discord: {
    clientId: "1252437898377166898",
    clientSecret: "tHbBBbmRsbwJZ5cO8gD3uiwuzEMZY9Pz",
    invite_link: "https://discord.gg/nUdUkzRN2f",
    redirect_url: "http://localhost:3000/discord",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1252437898377166898&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=token&scope=identify",
  },
  cloudinary: {
    name: "drnowcudq",
    preset: "atu_videos",
  },
  mode: "live", // maintenance, coming-soon, or live
  s3_bucket: "atu-staging",
  aws_access_key: "AKIATDM5GRNVDSWIALXZ",
  aws_secret_key: "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",
  aws_region: "us-west-2",
};

const production = {
  debug: false,
  api: `${process.env.REACT_APP_API_URL}`,
  rtm: `${process.env.REACT_APP_SOCKET_URL}`,
  // api: "http://localhost:3001/api",
  // rtm: "ws://localhost:3001/api",
  firebase: {
    apiKey: "AIzaSyCCs81h4-avdPrQNz5Oy4ttKFBJBWCPddA",
    authDomain: "alphatradinguniversity.firebaseapp.com",
    projectId: "alphatradinguniversity",
    storageBucket: "alphatradinguniversity.appspot.com",
    messagingSenderId: "268950678737",
    appId: "1:268950678737:web:7c47e047eb7e54f324e9f0",
  },
  loadingAnimation:
    "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
  stripe: {
    publishKey:
      "pk_test_51OHqsQIsr3YIbvSKzv2I3o9JimiT0AravE5vzkLFWXHjjzRw1IUtnC69jxHat1x6TfVvmxU6tMx7Q2i8xi9pozkU00wv1d4MkC",
    returnUrl: "http://localhost:3000/checkout",
  },
  discord: {
    clientId: "1252437898377166898",
    clientSecret: "tHbBBbmRsbwJZ5cO8gD3uiwuzEMZY9Pz",
    invite_link: "https://discord.gg/nUdUkzRN2f",
    redirect_url: "http://localhost:3000/discord",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1252437898377166898&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=token&scope=identify",
  },
  cloudinary: {
    name: "drnowcudq",
    preset: "atu_videos",
  },
  mode: "live",
  s3_bucket: "atu-prod",
  aws_access_key: "AKIATDM5GRNVDSWIALXZ",
  aws_secret_key: "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",
  aws_region: "us-west-2", // maintenance, coming-soon, or live
  // debug: false,
  // mode: "live",
  // api: "https://api.alphatradinguniversity.com",
  // rtm: "wss://api.alphatradinguniversity.com",
  // firebase: {
  //   apiKey: "AIzaSyCCs81h4-avdPrQNz5Oy4ttKFBJBWCPddA",
  //   authDomain: "alphatradinguniversity.firebaseapp.com",
  //   projectId: "alphatradinguniversity",
  //   storageBucket: "alphatradinguniversity.appspot.com",
  //   messagingSenderId: "268950678737",
  //   appId: "1:268950678737:web:7c47e047eb7e54f324e9f0",
  // },
  // loadingAnimation:
  //   "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
  // stripe: {
  //   publishKey:
  //     "pk_test_51OHqsQIsr3YIbvSKzv2I3o9JimiT0AravE5vzkLFWXHjjzRw1IUtnC69jxHat1x6TfVvmxU6tMx7Q2i8xi9pozkU00wv1d4MkC",
  //   returnUrl: "https://alphatradinguniversity.com/checkout",
  // },
  // discord: {
  //   clientId: "1252437898377166898",
  //   clientSecret: "tHbBBbmRsbwJZ5cO8gD3uiwuzEMZY9Pz",
  //   invite_link: "https://discord.gg/nUdUkzRN2f",
  //   redirect_url: "https://alphatradinguniversity.com/discord",
  //   authorization_url:
  //     "https://discord.com/api/oauth2/authorize?client_id=1252437898377166898&redirect_uri=https%3A%2F%2Falphatradinguniversity.com%2Fdiscord&response_type=token&scope=identify",
  // },
  // cloudinary: {
  //   name: "dvrzzzeg7",
  //   preset: "alphatradinguniversity",
  // },
};
const staging = {
  debug: false,
  api: `${process.env.REACT_APP_API_URL}`,
  rtm: `${process.env.REACT_APP_SOCKET_URL}`,
  // api: "http://localhost:3001/api",
  // rtm: "ws://localhost:3001/api",
  firebase: {
    apiKey: "AIzaSyCCs81h4-avdPrQNz5Oy4ttKFBJBWCPddA",
    authDomain: "alphatradinguniversity.firebaseapp.com",
    projectId: "alphatradinguniversity",
    storageBucket: "alphatradinguniversity.appspot.com",
    messagingSenderId: "268950678737",
    appId: "1:268950678737:web:7c47e047eb7e54f324e9f0",
  },
  loadingAnimation:
    "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
  stripe: {
    publishKey:
      "pk_test_51OHqsQIsr3YIbvSKzv2I3o9JimiT0AravE5vzkLFWXHjjzRw1IUtnC69jxHat1x6TfVvmxU6tMx7Q2i8xi9pozkU00wv1d4MkC",
    returnUrl: "http://localhost:3000/checkout",
  },
  discord: {
    clientId: "1252437898377166898",
    clientSecret: "tHbBBbmRsbwJZ5cO8gD3uiwuzEMZY9Pz",
    invite_link: "https://discord.gg/nUdUkzRN2f",
    redirect_url: "http://localhost:3000/discord",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1252437898377166898&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=token&scope=identify",
  },
  cloudinary: {
    name: "drnowcudq",
    preset: "atu_videos",
  },
  mode: "live",
  s3_bucket: "atu-staging",
  aws_access_key: "AKIATDM5GRNVDSWIALXZ",
  aws_secret_key: "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",
  aws_region: "us-west-2", // maintenance, coming-soon, or live
  // debug: false,
  // mode: "live",
  // api: "https://api.alphatradinguniversity.com",
  // rtm: "wss://api.alphatradinguniversity.com",
  // firebase: {
  //   apiKey: "AIzaSyCCs81h4-avdPrQNz5Oy4ttKFBJBWCPddA",
  //   authDomain: "alphatradinguniversity.firebaseapp.com",
  //   projectId: "alphatradinguniversity",
  //   storageBucket: "alphatradinguniversity.appspot.com",
  //   messagingSenderId: "268950678737",
  //   appId: "1:268950678737:web:7c47e047eb7e54f324e9f0",
  // },
  // loadingAnimation:
  //   "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
  // stripe: {
  //   publishKey:
  //     "pk_test_51OHqsQIsr3YIbvSKzv2I3o9JimiT0AravE5vzkLFWXHjjzRw1IUtnC69jxHat1x6TfVvmxU6tMx7Q2i8xi9pozkU00wv1d4MkC",
  //   returnUrl: "https://alphatradinguniversity.com/checkout",
  // },
  // discord: {
  //   clientId: "1252437898377166898",
  //   clientSecret: "tHbBBbmRsbwJZ5cO8gD3uiwuzEMZY9Pz",
  //   invite_link: "https://discord.gg/nUdUkzRN2f",
  //   redirect_url: "https://alphatradinguniversity.com/discord",
  //   authorization_url:
  //     "https://discord.com/api/oauth2/authorize?client_id=1252437898377166898&redirect_uri=https%3A%2F%2Falphatradinguniversity.com%2Fdiscord&response_type=token&scope=identify",
  // },
  // cloudinary: {
  //   name: "dvrzzzeg7",
  //   preset: "alphatradinguniversity",
  // },
};

export const AppConfig =
  process.env.REACT_APP_ATU_MODE === "debug" ? debug : process.env.REACT_APP_ATU_MODE === "staging" ? staging : production;
