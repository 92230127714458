import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./pages/404";
import PageLanding from "./pages/landing";
import ThemeManager from "./middleware/ThemeManager";
import { SnackbarProvider } from "notistack";
import PageLogin from "./pages/login";
import PageSignup from "./pages/join";
import PageRecovery from "./pages/recovery";
import AuthenticatedRoute from "./middleware/AuthenticatedRoute";
import PageDashboard from "./pages/dashboard";
import PageVerification from "./pages/verification";
import AuthHandler from "./pages/auth";
import PagePasswordReset from "./pages/password";
import { RecoilRoot } from "recoil";
import SplashScreen from "./components/SplashScreen";
import ModalProvider from "mui-modal-provider";
import PageAbout from "./pages/about";

import CheckoutContainer from "./middleware/CheckoutContainer";
import PageCheckout from "./pages/checkout";
import PageOnboarding from "./pages/onboarding";
import PageDiscordHandler from "./pages/discord";
import PageSubscriptionExpired from "./pages/expired";
import { AppConfig } from "./config";
import PageComingSoon from "./pages/coming-soon";
import PageMaintenance from "./pages/maintenance";
// Defines the routes and any middlewares needed for the application to function.
import { Analytics } from "@vercel/analytics/react";
function App() {
  const params = new URLSearchParams(window.location.search);
  const forceMode = params.get("forceMode") === "live";
  return (
    <RecoilRoot>
      <Analytics />
      <ThemeManager>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <ModalProvider>
            <Router>
              <Routes>
                {AppConfig.mode === "coming-soon" && (
                  <Route element={<PageComingSoon />} path="*" />
                )}
                {AppConfig.mode === "maintenance" && (
                  <Route element={<PageMaintenance />} path="*" />
                )}
                {(AppConfig.mode === "live" || forceMode) && (
                  <>
                    <Route element={<PageLanding />} path="/" />
                    {/* Matches with the root path for showing landing page  */}
                    {/* <Route element={<PageAbout />} path="/about" /> */}
                    <Route element={<AuthHandler />} path="/auth" />
                    <Route element={<PageLogin />} path="/login" />
                    <Route
                      element={<CheckoutContainer form={PageSignup} />}
                      path="/join"
                    />
                    <Route
                      element={
                        <CheckoutContainer form={PageSubscriptionExpired} />
                      }
                      path="/expired"
                    />
                    <Route element={<PageCheckout />} path="/checkout" />
                    <Route
                      element={
                        <AuthenticatedRoute
                          redirect={"/login"}
                          splash={<SplashScreen />}
                        >
                          <PageOnboarding />
                        </AuthenticatedRoute>
                      }
                      path="/onboarding"
                    />
                    <Route
                      path="/discord"
                      element={
                        <AuthenticatedRoute
                          redirect={"/login"}
                          splash={<SplashScreen />}
                        >
                          <PageDiscordHandler />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route
                      element={<PagePasswordReset />}
                      path="/password-reset"
                    />
                    <Route element={<PageRecovery />} path="/recovery" />
                    <Route element={<PageVerification />} path="/verify" />
                    <Route
                      path="/dashboard/*"
                      element={
                        <AuthenticatedRoute
                          redirect={"/login"}
                          splash={<SplashScreen />}
                        >
                          <PageDashboard />
                        </AuthenticatedRoute>
                      }
                    />

                    {/* If the path doesn't match anything, we end up showing 404 */}
                    <Route element={<PageNotFound />} path="/*" />
                  </>
                )}
              </Routes>
            </Router>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeManager>
    </RecoilRoot>
  );
}

export default App;
