import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {  Instagram, YouTube } from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function GlobalFooter() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const nav = useNavigate();

  return (
    <Stack
      alignItems="center"
      sx={{
        background: "rgba(14, 11, 52, 0.25)",
        borderTop: "1px solid rgba(60, 215, 205, 0.15)",
        padding: "32px 16px",
        width: "100%",
        zIndex: 100,
      }}
    >
      {/* Logo */}
      <Stack alignItems="center" spacing={2}>
        {/* Policy Links */}
        <Stack
          direction={isDesktop ? "row" : "column"}
          spacing={isDesktop ? "24px" : "12px"}
          sx={{
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Space Grotesk",
            fontWeight: 500,
          }}
        >
          <Button
            href="https://docs.google.com/document/d/1s3zUdn6IOO6gUuWRaZ8p8bNZLA8aRIuaNXi6gkmIRP8/edit?tab=t.0#heading=h.8fzbs5luikni"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Refund Policy
          </Button>
          <Button
            href="https://docs.google.com/document/d/1okr8pHblLcLSQ84O-AzRpoZj0ck5GdxsHqYnEVBB1yo/edit?tab=t.0"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Privacy Policy
          </Button>
          <Button
            href="https://docs.google.com/document/d/1CDQPj-D_Y7cDp76ylTbP2lwb17OgM4UsLoCVdVk8n-s/edit?tab=t.0#heading=h.5qugire9wfl0"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Terms & Conditions
          </Button>
        </Stack>

        {/* Social Media Icons */}
        <Stack direction="row" spacing="20px" sx={{ marginTop: "12px" }}>
          <IconButton
            href="https://www.instagram.com/alphatradinguniversity?igsh=MXFpN3ZyZnM4d3U3Mw%3D%3D&utm_source=qr"
            target="_blank"
            size="large"
            sx={{ background: "rgba(14, 11, 52, 0.25)" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            href="https://www.youtube.com/@Matty4wood"
            target="_blank"
            size="large"
            sx={{ background: "rgba(14, 11, 52, 0.25)" }}
          >
            <YouTube />
          </IconButton>
        </Stack>

        {/* Copyright */}
        <Typography
          sx={{
            fontSize: "12px",
            color: "#AAA",
            fontFamily: "Space Grotesk",
            fontWeight: 400,
            marginTop: "16px",
          }}
        >
          © {new Date().getFullYear()} Alpha Trading University. All rights
          reserved.
        </Typography>
      </Stack>
    </Stack>
  );
}
