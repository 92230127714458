import { Button, Stack, Typography, useTheme } from "@mui/material";
import { LOGO, NAV_LOGO } from "../../assets";
import { AnimatePresence, motion } from "framer-motion";
import { FbAuth } from "../../core/firebase";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { enqueueSnackbar } from "notistack";
import { RtmSetProfile } from "../../core/rtm";
import { useNavigate } from "react-router";
import { AppConfig } from "../../config";
import { RtmGetOnboardingVideo } from "../../core/rtm/user";
import GlobalFooter from "../landing/components/global-footer";

export default function PageOnboarding() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [video, setVideo] = useState("");

  async function save() {
    try {
      setBusy(true);
      await RtmSetProfile({
        boarded: true,
      });
      navigate("/dashboard");
    } catch (err: any) {
      enqueueSnackbar("Error updating your profile. ", { variant: "error" });
      console.error("Error updating user profile. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      const _cfg = await RtmGetOnboardingVideo();
      setVideo(_cfg);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      spacing={"100px"}
      sx={{
        background: theme.palette.background.default,
        overflow: "auto",
        height: "100vh",
        position: "relative",
        zIndex: 0,
      }}
    >
      <Stack
        flex={1}
        sx={{
          maxWidth: "1320px",
          alignSelf: "center",
          width: "100%",
          p: "48px",
          [theme.breakpoints.down("md")]: {
            alignItems: "center",
          },
        }}
        spacing={"32px"}
      >
        <Stack sx={{ width: "100%" }}>
          <motion.img
            src={NAV_LOGO}
            style={{
              width: "300px",
              objectFit: "contain",
              alignSelf: "flex-start",
              justifySelf: "flex-start",
            }}
            initial={{ translateY: -500 }}
            animate={{ translateY: 0 }}
            transition={{ duration: 2, stiffness: 20, type: "spring" }}
          />
          <Stack spacing={"8px"} sx={{ mt: "32px" }}>
            <motion.p
              style={{
                fontSize: 28,
                fontFamily: "Space Grotesk",
                fontWeight: "500",
              }}
              initial={{ translateY: -50, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              transition={{
                delay: 2,
                duration: 1,
                stiffness: 20,
                type: "spring",
              }}
            >
              Onboarding
            </motion.p>
            <motion.p
              style={{
                fontSize: 22,
                fontFamily: "Space Grotesk",
                fontWeight: "400",
              }}
              initial={{ translateY: -30, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              transition={{
                delay: 3,
                duration: 1,
                stiffness: 20,
                type: "spring",
              }}
            >
              Welcome to Alpha Trading University,{" "}
              {FbAuth.currentUser?.displayName}!
            </motion.p>
          </Stack>
        </Stack>

        <Stack flex={1} spacing={"32px"}>
          <ReactPlayer
            url={video}
            width={"100%"}
            height={"100%"}
            style={{
              borderRadius: "12px",
              overflow: "hidden",
              maxHeight: "720px",
            }}
          />
        </Stack>
        <Stack alignItems={"center"} spacing={"16px"}>
          <Typography
            style={{
              fontSize: 24,
              fontFamily: "Space Grotesk",
              fontWeight: "500",
            }}
          >
            What's Next?
          </Typography>
          <Button
            href={AppConfig.discord.authorization_url}
            variant="contained"
            disabled={busy}
          >
            Connect with Discord
          </Button>
          <Typography>or</Typography>
          <Button
            color="secondary"
            variant="text"
            onClick={save}
            disabled={busy}
          >
            Get Started
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          zIndex: -1,
          overflow: "visible",
        }}
      >
        <motion.div
          style={{
            background: "#3CD7CD",
            height: "500px",
            width: "500px",
            position: "absolute",
            filter: "blur(250px)",
            zIndex: 0,
            bottom: -500,
            pointerEvents: "none",
          }}
          animate={{ opacity: [0, 1, 0.4, 0.8, 0.2, 1] }}
          transition={{ duration: 30, repeat: Infinity, repeatType: "reverse" }}
        />
      </Stack>
      <GlobalFooter />
    </Stack>
  );
}
