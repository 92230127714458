import {
  Button,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LOGO, NAV_LOGO } from "../../../assets";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";

/**
 * Sticky header for the landing page.
 */
export default function StaticHeader() {
  const theme = useTheme();
  const nav = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack alignItems={"center"} p={"24px"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ maxWidth: "1320px", width: "100%" }}
        justifyContent={"space-between"}
      >
        <motion.a
          href="/"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
        >
          {/* Site Logo: Desktop  */}
          {isDesktop && (
            <img src={NAV_LOGO} alt="Alpha Trading University Logo" />
          )}
          {!isDesktop && (
            <img
              src={LOGO}
              alt="Alpha Trading University Logo"
              style={{ height: "40px", objectFit: "contain" }}
            />
          )}
        </motion.a>
        {/* Nav Buttons / Menu  */}
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          <Stack direction={"row"} spacing={"16px"}>
            {/* <Button
              color="inherit"
              size={isDesktop ? "large" : "small"}
              onClick={() => nav("/about")}
            >
              About
            </Button> */}
            <Button
              color="inherit"
              size={isDesktop ? "large" : "small"}
              onClick={() => nav("/join")}
            >
              Join ATU
            </Button>
            <Button
              onClick={() => nav("/login")}
              variant="contained"
              size={isDesktop ? "large" : "small"}
            >
              Login
            </Button>
          </Stack>
        </motion.div>
      </Stack>
    </Stack>
  );
}
