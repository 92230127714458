import _logo from "./brand/logo.png";
import _nav_logo from "./brand/nav_logo.svg";

// Icons
import _ic_school from "./icons/school.svg";
import _ic_training from "./icons/online-training.svg";
import _ic_meeting from "./icons/online-meeting.svg";
import _ic_tradingviewlight from "./icons/tradingviewlight.svg";
import _ic_apple_ratings from "./icons/apple-ratings.svg";
import _ic_playstore_ratings from "./icons/playstore-ratings.svg";

// Other company logos
import _ic_bloomberg from "./icons/bloomberg.png";
import _ic_forbes from "./icons/forbes.png";
import _ic_marketwatch from "./icons/marketwatch-logo.png";
import _ic_wallstreet from "./icons/the-wall-street-journal.png";
import _ic_yahoo from "./icons/yahoo.png";
import _twinsails from "./images/twinsails-white.png";
// import _twinsails from "./icons/twinsails.png";
import _pacific from "./images/pacifichillcapital-white.png";
// import _pacific from "./icons/pacifichillcapital.png";
import _originfx from "./icons/originfx.png";
import _quantower_logo from "./images/quantower-white.png"
// import _quantower_logo from "./images/quantower-logo.png"
import _quantify_logo from "./images/Quantify - Logo.png"
import _tradingview from "./images/tradingview-white.png";
// import _tradingview from "./icons/tradingview.png";
import _trustpilot from "./icons/trustpilot.svg";
import _mt5 from "./icons/mt5.png";

// Images, etc.
import _app_demo from "./icons/appdemo.png";
import _app_demo_v2 from "./icons/appdemo2.png";
import _arrow from "./icons/arrow.svg";

import _no_results from "./icons/no-results.png";

export const NO_RESULTS = _no_results;

export const LOGO = _logo;
export const NAV_LOGO = _nav_logo;

// Icons
export const IC_ONLINE_MEETING = _ic_meeting;
export const IC_ONLINE_TRAINING = _ic_training;
export const IC_SCHOOL = _ic_school;
export const IC_TRADINGVIEW_LIGHT = _ic_tradingviewlight;
export const IC_PLAYSTORE_RATINGS = _ic_playstore_ratings;
export const IC_APPLE_RATINGS = _ic_apple_ratings;

// Other Company Logos
export const TRUSTPILOT = _trustpilot;
export const IC_ORIGINFX = _originfx;
export const IC_MT5 = _mt5;
export const IC_PACIFIC = _pacific;
export const IC_TRADINGVIEW = _tradingview;
export const IC_TWINSAILS = _twinsails;
export const IC_BLOOMBERG = _ic_bloomberg;
export const IC_FORBES = _ic_forbes;
export const IC_MARKETWATCH = _ic_marketwatch;
export const IC_WALLSTREET = _ic_wallstreet;
export const IC_YAHOO = _ic_yahoo;

// Other images

export const IMG_APP_DEMO = _app_demo;
export const IMG_APP_DEMO_v2 = _app_demo_v2;
export const IMG_ARROW = _arrow;
export const IMG_QUANTIFY_LOGO = _quantify_logo;
export const IMG_QUANTOWER_LOGO = _quantower_logo;