import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ZInput from "../components/ZInput";
import { useEffect, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { WeeklyReport } from "../types/WeeklyReport";
import IC_NOTE_EDIT from "../assets/icons/ui/IC_NOTE_EDIT";
import IC_NOTE_ADD from "../assets/icons/ui/IC_NOTE_ADD";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Close, YouTube } from "@mui/icons-material";
import {
  RtmCreatePostA,
  RtmDeletePostA,
  RtmGetEventA,
  RtmGetEventsA,
  RtmUpdatePostA,
} from "../core/rtm/moderator";
import IC_NOTE_SAVE from "../assets/icons/ui/IC_NOTE_SAVE";
import IC_NOTE_REMOVE from "../assets/icons/ui/IC_NOTE_REMOVE";
import { useModal } from "mui-modal-provider";
import ConfirmationDialog from "./ConfirmationDialog";
import { AutocompleteOption } from "../components/ZAutoComplete";
import NamedChip from "../components/NamedChip";
import { GetValueForValues } from "../core/helper";
import Ic_add from "../assets/icons/ui/ic_add";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../core/firebase";
import IC_FILE from "../assets/icons/ui/IC_FILE";
import "@blocknote/core/fonts/inter.css";
import { getDefaultReactSlashMenuItems, SuggestionMenuController, useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { BlockNoteSchema, defaultBlockSpecs, filterSuggestionItems, insertOrUpdateBlock } from "@blocknote/core";
import { YouTubeBlock } from "../components/Blocknote/YouTubeBlock";

const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs, // Keep default blocks
    youtube: YouTubeBlock, // Add custom YouTube block
  },
});

// Add Slash Command "/youtube"
const insertYouTube = (editor: typeof schema.BlockNoteEditor) => ({
  title: "YouTube Embed",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "youtube" });
  },
  aliases: ["youtube", "video", "embed"],
  group: "Media",
  icon: <YouTube />,
});

export default function EditPostDialog(props: {
  onClose?: (result?: boolean) => void;
  post?: WeeklyReport;
  template?: any;
}) {
  const [post, setPost] = useState<WeeklyReport | undefined>(props.post);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const [events, setEvents] = useState<AutocompleteOption[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<AutocompleteOption[]>(
    []
  );
  const [eventSelectionText, setEventSelectionText] = useState("");
  const [ticker, setTicker] = useState<"long" | "short">("long");
  const editor = useCreateBlockNote({
    schema,
    initialContent: props.post?.content
      ? JSON.parse(props.post?.content!)
      : props.template,
  });

  // We force render content on upload progress changes.
  const [refresh, setRefresh] = useState(0);

  const files = useRef<
    {
      data: File;
      state: "uploading" | "uploaded" | "error" | "pending";
      progress: number;
      url: string;
    }[]
  >([]);

  // Let's keep track of attachments separately.
  const [attachments, setAttachments] = useState<
    { name: string; url: string; size: number; type: string }[]
  >([]);

  const theme = useTheme();

  const { showModal } = useModal();

  async function add(status: "draft" | "published") {
    try {
      setError("");
      if (!post?.title) {
        enqueueSnackbar("Please enter a post title. ", { variant: "error" });
        return;
      }
      if (!post?.date) {
        enqueueSnackbar("Please select a date for the analysis.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      if (selectedEvents) {
        let _events = [];
        for (let _evOption of selectedEvents) {
          _events.push(_evOption.id);
        }
        post.events = _events;
      }
      post.status = status;
      post.content = JSON.stringify(editor.document);
      for (let f of files.current) {
        if (f.state === "uploaded") {
          attachments.push({
            name: f.data.name,
            size: f.data.size,
            type: f.data.type,
            url: f.url,
          });
        }
      }
      post.attachments = attachments;
      if (props.post) {
        // we update
        await RtmUpdatePostA(post);
      } else {
        // we create
        await RtmCreatePostA(post);
      }
      enqueueSnackbar("The post has been created. ", { variant: "success" });
      props.onClose?.(true);
    } catch (err: any) {
      console.error("Error creating a new post. ", err);
      enqueueSnackbar("Error adding new post. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function deletePost() {
    const modal = showModal(ConfirmationDialog, {
      title: "Delete Post?",
      body: "Are you sure you want to delete this post?",
      async onClose(result) {
        modal.destroy();
        if (result) {
          await RtmDeletePostA(post!.id);
          props.onClose?.(true);
          enqueueSnackbar("The post has been deleted successfully. ", {
            variant: "success",
          });
        }
      },
    });
  }

  async function load() {
    if (props.post?.events) {
      const _eventDatas: AutocompleteOption[] = [];
      for (let _event of props.post.events) {
        if (typeof _event !== "string") {
          _eventDatas.push({
            id: _event.id,
            label: _event.title,
            data: _event,
          });
        }
      }
      setSelectedEvents([..._eventDatas]);
      setRefresh(new Date().getTime());
    }
    setAttachments(props.post?.attachments || []);
  }

  async function loadEvents() {
    try {
      setBusy(true);
      const _events = await RtmGetEventsA({
        search: eventSelectionText,
        page: 0,
        sort: {
          type: "date",
          value: "desc",
        },
      });
      let _out: AutocompleteOption[] = [];
      for (let _event of _events.data) {
        _out.push({
          id: _event.id,
          label: _event.title,
          data: _event,
        });
      }
      setEvents(_out);
    } catch (err: any) {
      enqueueSnackbar("Error loading events. ", { variant: "error" });
      console.error("Error loading events. ", err);
    }
    setBusy(false);
  }

  async function uploadFiles() {
    try {
      let i = 0;
      for (let file of files.current) {
        if (file.state === "pending") {
          // We upload this file
          file.state = "uploading";
          const r = ref(
            FbStorage,
            `/uploads/${FbAuth.currentUser?.uid}/attachments/${file.data.name}`
          );
          const task = uploadBytesResumable(r, await file.data!.arrayBuffer());
          task.on("state_changed", (snap) => {
            file.progress = (snap.bytesTransferred / snap.totalBytes) * 100;
            setRefresh(new Date().getTime());
          });
          task.then(async (t) => {
            if (t.state === "error") {
              file.state = "error";
            } else if (t.state === "success") {
              const url = await getDownloadURL(task.snapshot.ref);
              // Here we update profile
              enqueueSnackbar("File uploaded successfully.", {
                variant: "success",
              });
              file.state = "uploaded";
              file.progress = 100;
              file.url = url;
            }
            setRefresh(new Date().getTime());
          });
        }
        i += 1;
      }
    } catch (err: any) {
      enqueueSnackbar("Error uploading a file. ", { variant: "error" });
      console.log(err);
    }
  }

  useEffect(() => {
    loadEvents();
    load();
  }, []);

  let timer: any;

  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(loadEvents, 1000);
  }, [eventSelectionText]);

  return (
    <Dialog fullScreen open>
      {busy && <LinearProgress />}
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            {props.post && <IC_NOTE_EDIT />}
            {!props.post && <IC_NOTE_ADD />}
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            Create Weekly Report
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={"8px"} sx={{ py: "24px" }}>
          {/* Title and Relevant event fields  */}
          <Stack direction={"row"} spacing={"8px"}>
            {/* Title textbox container  */}
            <Stack flex={1}>
              <ZInput
                disabled={busy}
                text={post?.title}
                onUpdate={(t) => setPost({ ...(post as any), title: t })}
                errorText={
                  error === "title" ? "Title cannot be blank." : undefined
                }
                label="Title"
                placeholder="Enter a title..."
              />
            </Stack>
            {/* Event */}
            <Stack flex={1} spacing={"8px"}>
              <Typography fontWeight={500} fontSize={14}>
                Relevant Events
              </Typography>
              <Autocomplete
                options={events}
                freeSolo
                multiple
                key={refresh}
                onChange={(ev, values) => {
                  setSelectedEvents(values as any);
                }}
                value={selectedEvents}
                fullWidth
                ListboxProps={{
                  sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
                }}
                sx={{
                  borderRadius: "50px",
                }}
                size="small"
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    {...(params as any)}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      hiddenLabel: true,
                      sx: { borderRadius: "8px" },
                    }}
                    hiddenLabel
                    helperText={"Select a relevant event for this post. "}
                    placeholder={"Search for an event..."}
                    onChange={(t) =>
                      setEventSelectionText(t.currentTarget.value)
                    }
                    value={eventSelectionText}
                  />
                )}
                renderOption={(props, option, state, ownerState) => (
                  <MenuItem {...props}>
                    <Stack
                      direction={"row"}
                      sx={{ px: "24px", py: "8px" }}
                      spacing={"8px"}
                      alignItems={"center"}
                    >
                      <Typography fontSize={12} sx={{ opacity: 0.6 }}>
                        {moment
                          .unix(option.data.date)
                          .format("MMM DD, YYYY hh:mm A")}
                      </Typography>
                      <Typography>{option.label}</Typography>
                      <NamedChip
                        label="Impact"
                        value={option.data.impact}
                        color={GetValueForValues(
                          [
                            { option: "Low", value: undefined },
                            {
                              option: "Medium",
                              value: theme.palette.info.main,
                            },
                            { option: "High", value: theme.palette.error.main },
                          ],
                          option.data.impact
                        )}
                      />
                      {option.data.previous && (
                        <NamedChip
                          label="Previous"
                          value={option.data.previous}
                        />
                      )}
                      {option.data.forecast && (
                        <NamedChip
                          label="Forecast"
                          value={option.data.forecast}
                        />
                      )}
                      {option.data.country && (
                        <NamedChip
                          label="Currency"
                          value={option.data.country}
                        />
                      )}
                    </Stack>
                  </MenuItem>
                )}
              />
            </Stack>
          </Stack>
          <ZInput
            disabled={busy}
            text={post?.summary}
            onUpdate={(t) => setPost({ ...(post as any), summary: t })}
            errorText={
              error === "summary" ? "Summary cannot be blank." : undefined
            }
            multiline
            rows={5}
            label="Report Summary"
            placeholder="Write a short description for this report that will be shown in dashboard."
            helperText="Summary is shown on the dashboard card as is. Keep it short and simple."
          />

          <Typography>Your Analysis</Typography>
          <BlockNoteView
            slashMenu={false}
            editor={editor}
            theme={darkDefaultTheme}
            style={{ height: "600px", overflow: "auto" }}
          >
            <SuggestionMenuController
              triggerCharacter="/"
              getItems={async (query) =>
                filterSuggestionItems(
                  [
                    ...getDefaultReactSlashMenuItems(editor),
                    insertYouTube(editor),
                  ],
                  query
                )
              }
            />
          </BlockNoteView>
          {/* Date & Tags  */}

          <Stack direction={"row"} spacing={"8px"}>
            <Stack flex={1}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Typography
                  fontSize={14}
                  fontFamily={"Space Grotesk"}
                  fontWeight={500}
                >
                  Date
                </Typography>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0px",
                      background: "#ffffff16",
                      borderRadius: "8px",
                    },
                  }}
                  disabled={busy}
                  value={post?.date ? moment.unix(post.date) : undefined}
                  onChange={(v) => setPost({ ...post, date: v?.unix() } as any)}
                />
              </LocalizationProvider>
            </Stack>
            <Stack flex={1}>
              <Typography
                fontWeight={500}
                fontSize={14}
                fontFamily={"Space Grotesk"}
              >
                Tags
              </Typography>
              <Autocomplete
                options={[]}
                disablePortal
                freeSolo
                disabled={busy}
                autoSelect
                multiple
                autoFocus
                key={post?.tags as any}
                onChange={(ev, values) => {
                  setPost({ ...post, tags: values } as any);
                }}
                value={post?.tags}
                fullWidth
                ListboxProps={{
                  sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
                }}
                renderTags={(tags, tProps, state) =>
                  tags.map((t: any) => (
                    <Chip
                      deleteIcon={<Close height="16px" width="16px" />}
                      onDelete={(de) => {
                        // Remove from local
                        const _new = tags?.filter?.((s: any) => s !== t);
                        setPost({ ...post, tags: _new } as any);
                      }}
                      size="small"
                      label={t}
                      variant="filled"
                      color="primary"
                      sx={{
                        color: "#FFF",
                        borderRadius: "24px",
                        mx: "2px",
                      }}
                    />
                  ))
                }
                sx={{
                  borderRadius: "50px",
                }}
                size="small"
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    autoFocus
                    {...(params as any)}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      hiddenLabel: true,
                      sx: { borderRadius: "8px", minHeight: "55px" },
                    }}
                    hiddenLabel
                    multiline
                    maxRows={4}
                    helperText={
                      "Tags are useful when filtering posts of certain types, or in search."
                    }
                    placeholder={"Enter tags for the post. "}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={"8px"}>
            <Stack flex={1}>
              <Typography
                fontWeight={500}
                fontSize={14}
                fontFamily={"Space Grotesk"}
              >
                Long
              </Typography>
              <Autocomplete
                options={[]}
                disablePortal
                freeSolo
                disabled={busy}
                autoSelect
                multiple
                autoFocus
                key={post?.long as any}
                onChange={(ev, values) => {
                  setPost({ ...post, long: values } as any);
                }}
                value={post?.long}
                fullWidth
                ListboxProps={{
                  sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
                }}
                renderTags={(tags, tProps, state) =>
                  tags.map((t: any) => (
                    <Chip
                      deleteIcon={<Close height="16px" width="16px" />}
                      onDelete={(de) => {
                        // Remove from local
                        const _new = tags?.filter?.((s: any) => s !== t);
                        setPost({ ...post, long: _new } as any);
                      }}
                      size="small"
                      label={t}
                      variant="filled"
                      color="primary"
                      sx={{
                        color: "#FFF",
                        borderRadius: "24px",
                        mx: "2px",
                      }}
                    />
                  ))
                }
                sx={{
                  borderRadius: "50px",
                }}
                size="small"
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    autoFocus
                    {...(params as any)}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      hiddenLabel: true,
                      sx: { borderRadius: "8px", minHeight: "55px" },
                    }}
                    hiddenLabel
                    multiline
                    maxRows={4}
                    helperText={"Enter the recommended long currency pairs."}
                    placeholder={"Long "}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <Typography
                fontWeight={500}
                fontSize={14}
                fontFamily={"Space Grotesk"}
              >
                Shorts
              </Typography>
              <Autocomplete
                options={[]}
                disablePortal
                freeSolo
                disabled={busy}
                autoSelect
                multiple
                autoFocus
                key={post?.short as any}
                onChange={(ev, values) => {
                  setPost({ ...post, short: values } as any);
                }}
                value={post?.short}
                fullWidth
                ListboxProps={{
                  sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
                }}
                renderTags={(tags, tProps, state) =>
                  tags.map((t: any) => (
                    <Chip
                      deleteIcon={<Close height="16px" width="16px" />}
                      onDelete={(de) => {
                        // Remove from local
                        const _new = tags?.filter?.((s: any) => s !== t);
                        setPost({ ...post, short: _new } as any);
                      }}
                      size="small"
                      label={t}
                      variant="filled"
                      color="primary"
                      sx={{
                        color: "#FFF",
                        borderRadius: "24px",
                        mx: "2px",
                      }}
                    />
                  ))
                }
                sx={{
                  borderRadius: "50px",
                }}
                size="small"
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    autoFocus
                    {...(params as any)}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      hiddenLabel: true,
                      sx: { borderRadius: "8px", minHeight: "55px" },
                    }}
                    hiddenLabel
                    multiline
                    maxRows={4}
                    helperText={"Enter the recommended short currency pairs."}
                    placeholder={"Shorts "}
                  />
                )}
              />
            </Stack>
          </Stack>

          {/* Attachments  */}
          <Stack>
            <Typography>Attachments</Typography>
            <Typography fontSize={14} sx={{ opacity: 0.8 }}>
              Insert images, videos, screenshots, or related files.{" "}
            </Typography>
            <input
              id="file-input"
              style={{
                position: "absolute",
                opacity: 0,
                zIndex: -999999,
                width: 0,
                height: 0,
              }}
              multiple
              type="file"
              onChange={(f) => {
                if (f.target.files && f.target.files?.length > 0) {
                  for (let i = 0; i < f.target.files.length; i++) {
                    files.current.push({
                      data: f.target.files[i],
                      progress: 0,
                      state: "pending",
                      url: "",
                    });
                  }
                  uploadFiles();
                }
              }}
            />
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
              sx={{ mt: "12px" }}
              key={refresh}
            >
              {attachments.map((a, i) => (
                <Stack
                  sx={{
                    width: "128px",
                    height: "128px",
                    background: "#333",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                  alignItems={"center"}
                >
                  <IconButton
                    onClick={() => {
                      attachments.splice(i, 1);
                      setAttachments([...attachments]);
                    }}
                    title="Delete"
                    sx={{ position: "absolute", top: "2px", right: "2px" }}
                  >
                    <Close />
                  </IconButton>
                  {a.type.startsWith("image/") && (
                    <img
                      src={a.url}
                      style={{ objectFit: "contain", width: "100%", flex: 1 }}
                      alt={a.name}
                    />
                  )}
                  {!a.type.startsWith("image/") && (
                    <SvgIcon sx={{ flex: 1, height: "48px", width: "48px" }}>
                      <IC_FILE />
                    </SvgIcon>
                  )}
                  <Typography textAlign={"center"} fontSize={12}>
                    {a.name}
                  </Typography>
                  <Typography fontSize={12}>
                    {Math.ceil(a.size / 1024)} KB
                  </Typography>
                </Stack>
              ))}
              {attachments.length > 0 && (
                <Divider
                  orientation="vertical"
                  sx={{ height: "100px", width: "2px", alignSelf: "center" }}
                />
              )}
              {files.current.map((a, i) => (
                <Stack
                  sx={{
                    width: "128px",
                    height: "128px",
                    background: "#333",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={"8px"}
                >
                  {a.state === "uploaded" && (
                    <>
                      <IconButton
                        onClick={() => {
                          files.current.splice(i, 1);
                          setRefresh(new Date().getTime());
                        }}
                        title="Delete"
                        sx={{ position: "absolute", top: "2px", right: "2px" }}
                      >
                        <Close />
                      </IconButton>
                      {a.data.type.startsWith("image/") && (
                        <img
                          src={a.url}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            flex: 1,
                          }}
                          alt={a.data.name}
                        />
                      )}
                      {!a.data.type.startsWith("image/") && (
                        <SvgIcon
                          sx={{ flex: 1, height: "48px", width: "48px" }}
                        >
                          <IC_FILE />
                        </SvgIcon>
                      )}
                    </>
                  )}
                  {a.state !== "uploaded" && (
                    <CircularProgress
                      value={a.progress}
                      variant="determinate"
                    />
                  )}
                  <Typography textAlign={"center"} fontSize={12}>
                    {a.data.name}
                  </Typography>
                  <Typography fontSize={12}>
                    {Math.ceil(a.data.size / 1024)} KB
                  </Typography>
                </Stack>
              ))}
              <Stack
                onClick={() => document.getElementById("file-input")?.click()}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(de) => {
                  de.preventDefault();
                  if (de.dataTransfer.files.length > 0) {
                    // upload the files
                    for (let i = 0; i < de.dataTransfer.files.length; i++) {
                      files.current.push({
                        data: de.dataTransfer.files[i],
                        progress: 0,
                        state: "pending",
                        url: "",
                      });
                    }
                    uploadFiles();
                  }
                }}
                sx={{
                  width: "128px",
                  height: "128px",
                  background: "#3338",
                  borderRadius: "8px",
                  transition: "all .3s",
                  position: "relative",
                  ":hover": {
                    background: "#333",
                    cursor: "pointer",
                  },
                }}
                alignItems={"center"}
                justifyContent={"center"}
                spacing="12px"
              >
                <SvgIcon sx={{}}>
                  <Ic_add />
                </SvgIcon>
                <Typography fontSize={12} textAlign={"center"}>
                  Select a File
                </Typography>

                <Typography fontSize={10} textAlign={"center"}>
                  You can also drag & drop.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button
            disabled={busy}
            onClick={() => props.onClose?.(false)}
            color="inherit"
          >
            Cancel
          </Button>
          {post?.id && (
            <Button
              disabled={busy}
              startIcon={
                <SvgIcon>
                  <IC_NOTE_REMOVE />
                </SvgIcon>
              }
              onClick={deletePost}
              color="error"
            >
              Delete
            </Button>
          )}
          <Button
            disabled={busy}
            color="inherit"
            variant="contained"
            onClick={() => add("draft")}
          >
            Save Draft
          </Button>
          <Button
            disabled={busy}
            variant="contained"
            startIcon={
              <SvgIcon>
                <IC_NOTE_SAVE />
              </SvgIcon>
            }
            onClick={() => add("published")}
          >
            Publish
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
