import {
  Badge,
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LOGO } from "../../assets";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import ZInput from "../../components/ZInput";
import { Email } from "@mui/icons-material";
import { RestRequestPasswordReset } from "../../core/rest";
import GlobalFooter from "../landing/components/global-footer";

export default function PageRecovery() {
  const [linkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  async function send() {
    try {
      if (!email) {
        setError("email");
        return;
      }
      setBusy(true);
      await RestRequestPasswordReset(email);
      enqueueSnackbar("Password reset email has been sent.");
      setLinkSent(true);
    } catch (err: any) {}
    setBusy(false);
  }

  return (
    <Stack
      sx={{ minHeight: "100vh", transition: "all .2s", opacity: 1 }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack position={"relative"}>
        <img
          style={{
            top: "64px",
            left: "80px",
            pointerEvents: "none",
            height: isDesktop ? "128px" : "64px",
            margin: "48px",
          }}
          alt="Logo"
          src={LOGO}
        />
        <div
          style={{
            background: "#3CD7CD",
            height: "300px",
            width: "300px",
            position: "absolute",
            filter: "blur(220px)",
            zIndex: -1,
            bottom: 0,
            pointerEvents: "none",
          }}
        />
      </Stack>

      <Stack
        justifyContent={"center"}
        sx={{
          borderRadius: "38px",
          background:
            "linear-gradient(180deg, rgba(14, 11, 52, 0.1) 10%, rgba(60, 215, 205, 0.06) 100%)",
          border: "1px solid rgba(60, 215, 205, 0.2)",
          backdropFilter: "blur(10.5px)",
          px: "32px",
          py: "48px",
          [theme.breakpoints.down("md")]: {
            px: "24px",
          },
          marginBottom: "3rem"
        }}
      >
        {linkSent && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Badge color="primary" variant="dot">
                <Email sx={{ height: "64px", width: "64px" }} />
              </Badge>
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Check your email
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                We've sent a password reset link to your email.
              </Typography>
              <Button color="secondary" href="/login">
                Login
              </Button>
            </Stack>
          </>
        )}
        {!linkSent && (
          <>
            <Stack
              alignItems={"center"}
              sx={{
                mt: "32px",
                [theme.breakpoints.down("md")]: {
                  mt: 0,
                },
              }}
              spacing="12px"
            >
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{
                  color: "#F5F5F6",
                  fontFamily: "Space Grotesk",
                  [theme.breakpoints.down("md")]: {
                    fontSize: 24,
                  },
                }}
              >
                Forgot your password?
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#94969C",
                  [theme.breakpoints.down("md")]: {
                    fontSize: 14,
                  },
                }}
              >
                Don't worry. We can help you reset it.
              </Typography>
            </Stack>
            <FormGroup onSubmit={send}>
              <Stack
                spacing="20px"
                sx={{
                  mt: "32px",
                  width: "360px",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
              >
                <ZInput
                  text={email}
                  onUpdate={(t) => setEmail(t)}
                  label="Email"
                  important
                  placeholder="Enter your email "
                  startIcon={<Email />}
                  errorText={error === "email" ? "Invalid email address." : ""}
                />
                <Stack spacing={"2px"}>
                  <Link href="/login" underline="none" color={"secondary"}>
                    Know your password?
                  </Link>
                </Stack>
                <Button
                  onClick={send}
                  disabled={busy}
                  size="large"
                  type="submit"
                  sx={{ mt: "4px", height: "42px" }}
                  variant="contained"
                >
                  {busy && (
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                      style={{ height: "100px" }}
                    />
                  )}
                  {!busy && "Reset password"}
                </Button>
                <Stack
                  justifyContent={"center"}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ mt: "12px" }}
                  spacing="8px"
                >
                  <Typography color="#94969C" fontSize={"14px"}>
                    Don't have an account?
                  </Typography>
                  <Link
                    fontSize={"14px"}
                    href="/join"
                    underline="none"
                    color={"secondary"}
                  >
                    Sign up
                  </Link>
                </Stack>
              </Stack>
            </FormGroup>
          </>
        )}
      </Stack>
      <GlobalFooter />
    </Stack>
  );
}
