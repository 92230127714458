import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useModal } from "mui-modal-provider";
import TabPanel from "../../../components/TabPanel";
import { RtmGetConfigA, RtmSetConfigA } from "../../../core/rtm/admin";
import "@blocknote/core/fonts/inter.css";
import { getDefaultReactSlashMenuItems, SuggestionMenuController, useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { AppConfig } from "../../../config";
import CloudinaryUploadWidget from "../../../components/CloudinaryUploadWidget";
import IC_UPLOAD from "../../../assets/icons/ui/IC_UPLOAD";
import { BlockNoteSchema, defaultBlockSpecs, filterSuggestionItems, insertOrUpdateBlock } from "@blocknote/core";
import { YouTubeBlock } from "../../../components/Blocknote/YouTubeBlock";
import { YouTube } from "@mui/icons-material";

const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs, // Keep default blocks
    youtube: YouTubeBlock, // Add custom YouTube block
  },
});

// Add Slash Command "/youtube"
const insertYouTube = (editor: typeof schema.BlockNoteEditor) => ({
  title: "YouTube Embed",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "youtube" });
  },
  aliases: ["youtube", "video", "embed"],
  group: "Media",
  icon: <YouTube />,
});

export default function SectionPlatformConfigurationA() {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const { showModal } = useModal();
  const [tab, setTab] = useState<"basic" | "onboarding">("basic");
  const [busy, setBusy] = useState(false);
  const editor = useCreateBlockNote({schema});
  const editor2 = useCreateBlockNote({schema});

  const [weeklyReportTemplate, setWeeklyReportTemplate] = useState("");
  const [eventReportTemplate, setEventReportTemplate] = useState("");
  const [onboardingVideo, setOnboardingVideo] = useState("");

  const [saveState, setSaveState] = useState<"saving" | "saved" | "error">();

  // File upload

  // Upload Widget Configuration
  // Remove the comments from the code below to add
  // additional functionality.
  // Note that these are only a few examples, to see
  // the full list of possible parameters that you
  // can add see:
  //   https://cloudinary.com/documentation/upload_widget_reference

  const [uwConfig] = useState({
    cloudName: AppConfig.cloudinary.name,
    uploadPreset: AppConfig.cloudinary.preset,
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    sources: ["local", "url"], // restrict the upload sources to URL and local files
    multiple: false, //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    clientAllowedFormats: ["video"],
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });

  async function uploadComplete(info: any) {
    await RtmSetConfigA("onboarding_video", info.secure_url);
    setOnboardingVideo(info.secure_url);
    enqueueSnackbar("Onboarding video updated successfully.", {
      variant: "success",
    });
  }

  async function load() {
    try {
      setBusy(true);
      const _cfg = await RtmGetConfigA<string>("analysis_template");
      setWeeklyReportTemplate(_cfg?.value || "");
      if (_cfg.value) {
        editor.replaceBlocks(editor.document, JSON.parse(_cfg.value));
      }

      const _cfg2 = await RtmGetConfigA<string>("event_template");
      setEventReportTemplate(_cfg2.value || "");
      if (_cfg2.value) {
        editor2.replaceBlocks(editor2.document, JSON.parse(_cfg2.value));
      }

      const _video = await RtmGetConfigA<string>("onboarding_video");
      setOnboardingVideo(_video?.value);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);

      setSaveState("error");
    }
    setBusy(false);
  }

  async function save() {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("analysis_template", weeklyReportTemplate);
      await RtmSetConfigA("event_template", eventReportTemplate);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ p: "24px", overflow: "hidden", flex: 1 }} gap={"8px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Platform Configuration
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          {saveState === "saving" && (
            <>
              <CircularProgress size={"24px"} />
              <Typography>Saving changes...</Typography>
            </>
          )}
          {saveState === "saved" && (
            <>
              <Typography>Changes are saved.</Typography>
            </>
          )}

          {saveState === "error" && (
            <>
              <Typography color={"error"}>Error saving changes!</Typography>
            </>
          )}
          <Button onClick={save} variant="contained" disabled={busy}>
            Save
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1} sx={{ overflow: "hidden", flex: 1 }} spacing="8px">
        {/* Tabbed Navigation */}
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Templates" value={"basic"} />
          <Tab label="Onboarding" value={"onboarding"} />
        </Tabs>
        <TabPanel index={"basic"} value={tab}>
          <Stack spacing={"32px"} sx={{ overflow: "auto" }}>
            <Stack>
              <Typography fontSize={20} fontFamily={"Space Grotesk"}>
                Weekly Report Template
              </Typography>
              <BlockNoteView
                slashMenu={false}
                theme={darkDefaultTheme}
                editor={editor}
                onChange={() => {
                  setWeeklyReportTemplate(JSON.stringify(editor.document));
                }}
                style={{ height: "600px", overflow: "auto" }}
              >
                <SuggestionMenuController
                  triggerCharacter="/"
                  getItems={async (query) =>
                    filterSuggestionItems(
                      [
                        ...getDefaultReactSlashMenuItems(editor),
                        insertYouTube(editor),
                      ],
                      query
                    )
                  }
                />
              </BlockNoteView>
              {/* <Markdown
                value={weeklyReportTemplate}
                onBlur={save}
                onChange={(v) => setWeeklyReportTemplate(v || "")}
                style={{
                  background: "#ffffff16",
                  borderRadius: "8px",
                  border: "0px solid #000",
                  outline: "0px solid gray",
                  color: "white",
                  minHeight: "500px",
                }}
                previewOptions={{
                  style: {
                    background: "transparent",
                    color: "white",
                  },
                }}
              /> */}
            </Stack>

            <Stack>
              <Typography fontSize={20} fontFamily={"Space Grotesk"}>
                Daily Event Report Template
              </Typography>
              <BlockNoteView
                slashMenu={false}
                editor={editor2}
                theme={darkDefaultTheme}
                onChange={() => {
                  setEventReportTemplate(JSON.stringify(editor2.document));
                }}
                style={{ height: "600px", overflow: "auto" }}
              >
                <SuggestionMenuController
                  triggerCharacter="/"
                  getItems={async (query) =>
                    filterSuggestionItems(
                      [
                        ...getDefaultReactSlashMenuItems(editor),
                        insertYouTube(editor),
                      ],
                      query
                    )
                  }
                />
              </BlockNoteView>
              {/* <Markdown
                value={eventReportTemplate}
                onBlur={save}
                onChange={(v) => setEventReportTemplate(v || "")}
                style={{
                  background: "#ffffff16",
                  borderRadius: "8px",
                  border: "0px solid #000",
                  outline: "0px solid gray",
                  color: "white",
                  minHeight: "500px",
                }}
                previewOptions={{
                  style: {
                    background: "transparent",
                    color: "white",
                  },
                }}
              /> */}
            </Stack>
          </Stack>
        </TabPanel>

        <TabPanel index={"onboarding"} value={tab}>
          <Stack spacing={"32px"} sx={{ overflow: "auto", maxWidth: "800px" }}>
            <Typography>Onboarding Video</Typography>
            {!onboardingVideo && (
              <Stack
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ cursor: "pointer" }}
              >
                <IC_UPLOAD height={256} width={256} />
                <Typography>
                  Click the button below to upload a video for onboarding
                  screen.
                </Typography>
              </Stack>
            )}
            {onboardingVideo && (
              <video
                src={onboardingVideo}
                autoPlay
                loop
                controls
                muted
                style={{ height: "100%", borderRadius: "16px" }}
              />
            )}
            <CloudinaryUploadWidget
              uwConfig={uwConfig}
              onUploadComplete={uploadComplete}
            />
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
}
