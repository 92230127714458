import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useModal } from "mui-modal-provider";
import { RtmGetPricePlans, RtmGetProfile, RtmSetProfile } from "../../core/rtm";
import { FbAuth, FbStorage } from "../../core/firebase";
import Ic_back from "../../assets/icons/ui/ic_back";
import TabPanel from "../../components/TabPanel";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { User } from "../../types/User";
import ZInput from "../../components/ZInput";
import { PlatformPricing } from "../../types/PlatformPricing";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import {
  ArrowOutward,
  ArrowUpwardSharp,
  CheckCircle,
} from "@mui/icons-material";
import { RtmGetBillingInfo, RtmGetCustomerPortal } from "../../core/rtm/user";
import moment from "moment";
import Stripe from "stripe";
import { Role } from "../../types/Role";

export default function SectionProfile() {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const { showModal } = useModal();
  const [tab, setTab] = useState<"basic" | "security" | "billing">("basic");
  const [busy, setBusy] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [profile, setProfile] = useState<User>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [discordUsername, setDicordUsername] = useState(""); 
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [image, setImage] = useState("");

  const [saveState, setSaveState] = useState<"saving" | "saved" | "error">();

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [pricing, setPricing] = useState<PlatformPricing[]>([]);
  const [billing, setBilling] = useState<{
    customer: any;
    invoices: any[];
    subscription: any;
    paymentMethod: any;
    nextInvoice: any;
  }>();

  async function load() {
    try {
      setBusy(true);
      const _prof = await RtmGetProfile();
      if (_prof) {
        setProfile(_prof);
        setFirstName(_prof.firstName);
        setLastName(_prof.lastName);
        _prof.discord && setDicordUsername(_prof.discord.username)
        setImage(_prof.image || "");
        setNewPassword("");
        setNewPassword2("");
        setOldPassword("");
      }

      if (_prof && _prof.role === Role.User) {
        const _pricing = await RtmGetPricePlans();
        console.log({ _pricing });

        setPricing(_pricing);

        const _billing = await RtmGetBillingInfo();
        console.log({_billing})
        setBilling(_billing);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading profile. ", err);

      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveProfile() {
    try {
      setBusy(true);
      setSaveState("saving");
      // Update first and last name
      await RtmSetProfile({
        firstName: firstName,
        lastName: lastName,
      });
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function uploadPhoto(attachment: File) {
    try {
      setBusy(true);
      setSaveState("saving");
      const r = ref(
        FbStorage,
        `/users/${FbAuth.currentUser?.uid}/media/${attachment.name}`
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer());
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          // Here we update profile
          await RtmSetProfile({ image: url });
          await updateProfile(FbAuth.currentUser!, {
            photoURL: url,
          });
          enqueueSnackbar("Photo updated successfully.", {
            variant: "success",
          });
          setSaveState("saved");
          load();
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
      setSaveState("error");
    }
  }

  async function changePassword() {
    try {
      if (!oldPassword) {
        enqueueSnackbar("Please enter your old password. ", {
          variant: "error",
        });
        return;
      }
      if (!newPassword || !newPassword2 || newPassword !== newPassword2) {
        enqueueSnackbar(
          "Invalid password. Please make sure the passwords match.",
          { variant: "error" }
        );
        return;
      }
      setBusy(true);
      const oldCreds = await EmailAuthProvider.credential(
        profile?.email!,
        oldPassword
      );
      await reauthenticateWithCredential(FbAuth.currentUser!, oldCreds);
      await updatePassword(FbAuth.currentUser!, newPassword);
      enqueueSnackbar("Your password has been changed successfully. ", {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar("Error updating password. ", { variant: "error" });
      console.error("Error updating password for a user.");
      console.error(err);
    }
    setBusy(false);
  }

  async function removePhoto() {
    try {
      setBusy(true);
      // Update profile
      await updateProfile(FbAuth.currentUser!, {
        photoURL: "",
      });
      await RtmSetProfile({ image: "" });
      setImage("");
      setUploadInfo("");
      enqueueSnackbar("Profile photo has been removed.", { variant: "info" });
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.error("Error removing profile photo.");
      console.error(err);
    }
    setBusy(false);
  }

  async function manageSubscription() {
    try {
      const _link = await RtmGetCustomerPortal();
      if (_link) {
        window.open(_link, "_blank");
      }
    } catch (err: any) {
      console.error("Error loading customer portal. ", err);
      enqueueSnackbar("Error loading stripe customer portal. ", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    load();
  }, []);

  function RenderPricePlan() {
    if (pricing.length <= 0) return <></>;
    const price = pricing[0];
    let _priceInfo = parseStripeAmount(price?.amount || 0);
    let _schedule = "monthly";
    // let _schedule = "lifetime";
    // if (price.kind === "recurring") {
    //   switch (price!.schedule) {
    //     case "3-month":
    //       _schedule = "every 3 months";
    //       break;
    //     case "daily":
    //       _schedule = "daily";
    //       break;
    //     case "6-month":
    //       _schedule = "every 6 months";
    //       break;
    //     case "monthly":
    //       _schedule = "monthly";
    //       break;
    //     case "weekly":
    //       _schedule = "weekly";
    //       break;
    //     case "yearly":
    //       _schedule = "yearly";
    //       break;
    //   }
    // }
    const _price = `${getCurrencySymbol(price.currency || "usd")}${
      _priceInfo.dollars
    }.${_priceInfo.cents}`;
    return (
      <Stack
        flex={1}
        sx={{
          p: "24px",
          background: "rgba(36, 36, 36, 0.39)",
          border: "1px solid rgba(56, 56, 56, 0.50)",
          borderRadius: "8px",
          position: "relative",
        }}
        spacing="8px"
      >
        <Stack>
          <Typography fontSize={22} fontFamily={"Space Grotesk"}>
            {price.name}
          </Typography>
          <Typography>{price.description}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {_price}
          </Typography>
          <Chip
            label={_schedule}
            sx={{ textTransform: "capitalize" }}
            size="small"
          />
          {billing && billing.subscription?.status === "past_due" && (
            <Chip
              label={"Past Due"}
              sx={{ textTransform: "capitalize" }}
              size="small"
              color="error"
            />
          )}
        </Stack>
        {Boolean(price.trial) && price.trial! > 0 && (
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography fontSize={12}>Trial Period:</Typography>
            <Chip
              label={price.trial + " days"}
              sx={{ textTransform: "capitalize" }}
              size="small"
            />
          </Stack>
        )}
      </Stack>
    );
  }

  function RenderPaymentMethod() {
    if (!billing || !billing.paymentMethod) return <></>;
    return (
      <Stack
        flex={1}
        sx={{
          p: "24px",
          background: "rgba(36, 36, 36, 0.39)",
          border: "1px solid rgba(56, 56, 56, 0.50)",
          borderRadius: "8px",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
        spacing="8px"
      >
        <Stack>
          <Typography
            fontSize={22}
            fontFamily={"Space Grotesk"}
            sx={{ textTransform: "capitalize" }}
          >
            {billing.paymentMethod.type}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography
            fontFamily={"Space Grotesk"}
            fontSize={26}
            sx={{ textTransform: "capitalize" }}
          >
            {billing.paymentMethod.card?.brand}
          </Typography>
          <Chip
            label={"**** **** **** " + billing.paymentMethod.card.last4}
            sx={{ textTransform: "capitalize" }}
            size="small"
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography>Expiry</Typography>
          <Chip
            label={
              billing.paymentMethod.card.exp_month +
              "/" +
              billing.paymentMethod.card.exp_year
            }
            size="small"
          />
        </Stack>
      </Stack>
    );
  }

  function RenderNextInvoice() {
    if (!billing || !billing.nextInvoice) return <></>;
    let _priceInfo = parseStripeAmount(billing.nextInvoice.total || 0);
    return (
      <Stack
        flex={1}
        sx={{
          p: "24px",
          background: "rgba(36, 36, 36, 0.39)",
          border: "1px solid rgba(56, 56, 56, 0.50)",
          borderRadius: "8px",
          position: "relative",
        }}
        spacing="8px"
      >
        <Stack>
          <Typography
            fontSize={22}
            fontFamily={"Space Grotesk"}
            sx={{ textTransform: "capitalize" }}
          >
            Next Invoice
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography
            fontFamily={"Space Grotesk"}
            fontSize={26}
            sx={{ textTransform: "capitalize" }}
          >
            {getCurrencySymbol(billing.nextInvoice.currency)}
            {_priceInfo.dollars}.{_priceInfo.cents}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography>Date</Typography>
          <Chip
            label={moment
              .unix(billing.nextInvoice.created)
              .format("MMM DD, YYYY hh:mm A")}
            size="small"
          />
        </Stack>
      </Stack>
    );
  }

  function RenderInvoiceItem(inv: Stripe.Invoice) {
    let _priceInfo = parseStripeAmount(inv.total || 0);

    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        rowGap={"18px"}
        columnGap={"18px"}
        sx={{
          p: "12px",
          background: "rgba(36, 36, 36, 0.39)",
          border: "1px solid rgba(56, 56, 56, 0.50)",
          borderRadius: "8px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Stack>
          <Typography>ID</Typography>
          <Typography fontSize={14}>{inv.id}</Typography>
        </Stack>

        <Stack>
          <Typography>Date Created</Typography>
          <Typography fontSize={14}>
            {moment.unix(inv.created).format("MMM DD, YYYY hh:mm A")}
          </Typography>
        </Stack>

        <Stack alignItems={"start"}>
          <Typography>Status</Typography>
          <Chip
            label={inv.status}
            size="small"
            sx={{ textTransform: "capitalize" }}
          />
        </Stack>

        <Stack>
          <Typography>Amount</Typography>
          <Typography fontSize={14}>
            {getCurrencySymbol(inv.currency)}
            {_priceInfo.dollars}.{_priceInfo.cents}
          </Typography>
        </Stack>

        <Stack>
          <Typography>Actions</Typography>
          {inv.invoice_pdf && (
            <Typography
              fontSize={14}
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(inv.invoice_pdf!, "_blank")}
            >
              Download PDF
            </Typography>
          )}
          {!inv.invoice_pdf && inv.hosted_invoice_url && (
            <Typography
              fontSize={14}
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(inv.hosted_invoice_url!, "_blank")}
            >
              View
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ p: "24px", overflow: "hidden", flex: 1 }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        <Stack direction="row">
          <IconButton onClick={() => navigate("/dashboard/")}>
            <Ic_back width={24} height={24} />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "Space Grotesk",
              fontWeight: 600,
              fontSize: 28,
              [theme.breakpoints.down("md")]: {
                fontSize: 20,
              },
            }}
          >
            Profile
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          {saveState === "saving" && (
            <>
              <CircularProgress size={"24px"} />
              <Typography>Saving changes...</Typography>
              {uploadState === "uploading" && (
                <Typography>{Math.ceil(uploadProgress)}%</Typography>
              )}
            </>
          )}
          {saveState === "saved" && (
            <>
              <Typography>Changes are saved.</Typography>
            </>
          )}

          {saveState === "error" && (
            <>
              <Typography color={"error"}>Error saving changes!</Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1} sx={{ overflow: "hidden", flex: 1 }} spacing="8px">
        {/* Tabbed Navigation */}
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Basic" value={"basic"} />
          {profile?.role === Role.User && (
            <Tab label="Billing" value={"billing"} />
          )}
          <Tab label="Security" value={"security"} />
        </Tabs>
        <TabPanel index={"basic"} value={tab}>
          <Stack spacing={"40px"} sx={{ overflow: "auto" }}>
            <Stack direction={"row"} spacing={"24px"}>
              <Stack sx={{ position: "relative" }}>
                <Avatar
                  sx={{ height: "96px", width: "96px" }}
                  src={image || uploadInfo}
                />
                {uploadState === "uploading" && (
                  <CircularProgress
                    variant="determinate"
                    value={uploadProgress}
                    size="96px"
                    sx={{ position: "absolute" }}
                  />
                )}
              </Stack>
              <Stack
                flex={1}
                sx={{ height: "100%" }}
                justifyContent={"space-around"}
              >
                <Typography fontSize={18} fontWeight={400}>
                  Profile Photo
                </Typography>
                <Stack direction={"row"} spacing={"8px"}>
                  <input
                    id="file-input"
                    style={{
                      position: "absolute",
                      opacity: 0,
                      zIndex: -999999,
                    }}
                    type="file"
                    onChange={(f) => {
                      if (f.target.files && f.target.files?.length > 0) {
                        uploadPhoto(f.target.files[0]);
                      }
                    }}
                  />
                  <Button
                    onClick={() =>
                      document.getElementById("file-input")?.click()
                    }
                    disabled={busy}
                    color="secondary"
                    variant="contained"
                  >
                    {image || uploadInfo ? "Change Photo" : "Upload Photo"}
                  </Button>
                  {(image || uploadInfo) && (
                    <Button disabled={busy} onClick={removePhoto} color="error">
                      Remove Photo
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack spacing={"8px"}>
              <Stack spacing="8px" direction={"row"}>
                <ZInput
                  disabled={busy}
                  label="First Name"
                  text={firstName}
                  onUpdate={(t) => setFirstName(t)}
                  sx={{ width: "380px" }}
                />
                <ZInput
                  disabled={busy}
                  label="Last Name"
                  text={lastName}
                  onUpdate={(t) => setLastName(t)}
                  sx={{ width: "380px" }}
                />
              </Stack>
              <Stack spacing="8px" direction={"row"}>
                <ZInput
                  disabled={busy}
                  label="Discord Username"
                  text={discordUsername}
                  onUpdate={(t) => setDicordUsername(t)}
                  sx={{ width: "380px" }}
                />
              </Stack>
              <Button
                onClick={saveProfile}
                variant="contained"
                sx={{ alignSelf: "flex-start" }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </TabPanel>
        {profile?.role === Role.User && (
          <TabPanel index={"billing"} value={tab}>
            <Stack
              flex={1}
              spacing={"24px"}
              sx={{ overflow: "auto", height: "auto" }}
            >
              <Stack
                direction={"row"}
                rowGap={"18px"}
                columnGap={"18px"}
                flexWrap={"wrap"}
              >
                {/* Current Plan Card */}
                <Stack
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  {RenderPricePlan()}
                </Stack>
                {/* Payment Method Card  */}
                <Stack
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  {RenderPaymentMethod()}
                </Stack>
                {/* Next invoice  */}
                <Stack
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  {RenderNextInvoice()}
                </Stack>
              </Stack>
              {/* Manage Button  */}
              <Stack
                sx={{
                  p: "24px",
                  background: "rgba(36, 36, 36, 0.39)",
                  border: "1px solid rgba(56, 56, 56, 0.50)",
                  borderRadius: "8px",
                  position: "relative",
                  cursor: "pointer",
                  transition: "all .2s",
                  ":hover": {
                    background: "rgba(36, 36, 36, 1)",
                    border: "1px solid rgba(56, 56, 56, 1)",
                  },
                }}
                spacing="8px"
                onClick={manageSubscription}
              >
                <Stack>
                  <Typography
                    fontSize={22}
                    fontFamily={"Space Grotesk"}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 18,
                      },
                    }}
                  >
                    Manage Subscription <ArrowOutward />
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <Typography fontFamily={"Space Grotesk"} fontSize={14}>
                    Cancel your subscription, view or manage payment methods, or
                    switch between plans.
                  </Typography>
                </Stack>
              </Stack>
              {/* Invoices  */}
              <Stack spacing="8px">
                <Typography>Previous Invoices</Typography>
                {billing?.invoices?.map((i) => RenderInvoiceItem(i))}
              </Stack>
            </Stack>
          </TabPanel>
        )}
        <TabPanel index={"security"} value={tab}>
          <Stack spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"}>
              Update Password
            </Typography>
            <ZInput
              disabled={busy}
              label={"Old Password"}
              text={oldPassword}
              password
              onUpdate={(t) => {
                setOldPassword(t);
              }}
              placeholder="Enter old password..."
              sx={{ width: "380px" }}
            />
            <Stack spacing="8px" direction={"row"}>
              <ZInput
                disabled={busy}
                label={"New Password"}
                text={newPassword}
                password
                onUpdate={(t) => {
                  setNewPassword(t);
                }}
                placeholder="Enter new password..."
                sx={{ width: "380px" }}
              />
              <ZInput
                disabled={busy}
                label={"Confirm Password"}
                text={newPassword2}
                password
                onUpdate={(t) => {
                  setNewPassword2(t);
                }}
                placeholder="Confirm new password..."
                sx={{ width: "380px" }}
              />
            </Stack>
            <Button
              onClick={changePassword}
              variant="contained"
              sx={{ alignSelf: "flex-start" }}
            >
              Change Password
            </Button>
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
}
