import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Button, FilledInput } from "@mui/material";

// Function to convert YouTube share link to embed link
const convertToEmbedURL = (url: string) => {
  try {
    const regex =
      /(?:youtu\.be\/|youtube\.com\/(?:.*v=|.*\/|.*vi\/|.*v%3D|.*videos\/|.*embed\/|.*user\/.*#p\/.*\/|.*e\/|.*watch\?v=|.*\?v=|.*&v=))([^#&?]*).*/;
    const match = url.match(regex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : url;
  } catch (error) {
    return url;
  }
};

// Define the custom YouTube embed block
export const YouTubeBlock = createReactBlockSpec(
  {
    type: "youtube",
    propSchema: {
      url: { default: "" }, // Store YouTube embed URL
      textAlignment: defaultProps.textAlignment,
    },
    content: "none", // No inline content, only iframe
  },
  {
    render: ({ block, editor }) => {
      return (
        <div className="youtube-embed w-full flex items-center gap-2">
          {block.props.url ? (
            <iframe
              width="560"
              height="315"
              src={block.props.url}
              frameBorder="0"
              allowFullScreen
              className="rounded-lg"
            />
          ) : (
            <div className="w-full flex row items-center gap-2">
              {/* <input
                type="text"
                placeholder="Paste YouTube video URL"
                className="w-full p-3 bg-gray-800 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const embedUrl = convertToEmbedURL(e.currentTarget.value);
                    editor.updateBlock(block, { props: { url: embedUrl } });
                  }
                }}
              /> */}
              <FilledInput
                type="text"
                size="medium"
                placeholder="Paste YouTube video URL"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const embedUrl = convertToEmbedURL(e.currentTarget.value);
                    editor.updateBlock(block, { props: { url: embedUrl } });
                  }
                }}
                sx={{
                  borderRadius: "8px",
                  //height: "44px",
                  minHeight: "44px",
                  //maxHeight: "44px",
                  px: "0",
                  width: "100%",
                }}
                inputProps={{
                  style: {
                    paddingRight: "14px",
                    paddingLeft: "14px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              />
              {/* <button
                onClick={() => {
                  const inputElement = document.querySelector<HTMLInputElement>(
                    ".youtube-embed input"
                  );
                  if (inputElement) {
                    const embedUrl = convertToEmbedURL(inputElement.value);
                    editor.updateBlock(block, { props: { url: embedUrl } });
                  }
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Embed
              </button> */}
              <Button
                onClick={() => {
                  const inputElement = document.querySelector<HTMLInputElement>(
                    ".youtube-embed input"
                  );
                  if (inputElement) {
                    const embedUrl = convertToEmbedURL(inputElement.value);
                    editor.updateBlock(block, { props: { url: embedUrl } });
                  }
                }}
                size="small"
                sx={{ mt: "4px", height: "42px" }}
                variant="contained"
              >
                Embed
              </Button>
            </div>
          )}
        </div>
      );
    },
  }
);
