import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { act, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Chapter } from "../../../types/Chapter";
import { RtmGetChapter, RtmSetLessonProgress } from "../../../core/rtm/user";
import { Lesson } from "../../../types/Lesson";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { LOGO } from "../../../assets";
import Ic_back from "../../../assets/icons/ui/ic_back";
import IC_LOCK from "../../../assets/icons/ui/IC_LOCK";
import { Quiz } from "../../../types/Quiz";
import QuizView from "../../../components/Quiz";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { duration } from "moment";
import { formatVideoDuration } from "../../../core/helper";
import { useRecoilState } from "recoil";
import { aShowSidebar } from "../../../core/atoms";
import ImageModal from "../../../components/ImageEnlargeModal";

export default function SectionChapter() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const params = useParams();
  const chapterId = params.id;
  const lessonId = params.lesson;
  const [activeLesson, setActiveLesson] = useState<Lesson>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);
  const [imageData, setImageData] = useState<any | undefined>(undefined);

  const [chapter, setChapter] = useState<Chapter>();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [quiz, setQuiz] = useState<Quiz>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);
  const editor = useCreateBlockNote();

  async function load() {
    if (!chapterId) return;
    try {
      setBusy(true);
      const _details = await RtmGetChapter(chapterId!);
      setChapter(_details);
      setLessons(_details.lessons);
      if (lessonId) {
        setActiveLesson(_details.lessons.find((l) => l.id === lessonId));
      } else {
        const content = _details.details ? _details.details.trim() : "{}";

        try {
          const parsedContent = JSON.parse(content);
          editor.replaceBlocks(editor.document, parsedContent);
        } catch (error) {
          console.error("JSON parsing failed:", error, "Content:", content);
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  function RenderLesson(lesson: Lesson) {
    console.log({ lesson });
    return (
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          height: "150px",
          borderRadius: "8px",
          transition: "all .2s",
          cursor: "pointer",
          background: activeLesson?.id === lesson.id ? "#245" : "#222",
          ":hover": {
            background: "#333",
          },
          pointerEvents:
            activeLesson?.id === lesson.id || lesson.locked ? "none" : "all",
        }}
        spacing={"18px"}
        onClick={() => setActiveLesson(lesson)}
      >
        {/* Thumbnail  */}
        <Stack
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img
            src={lesson.thumbnail || LOGO}
            alt={lesson.title}
            style={{
              maxHeight: "100%",
              width: isDesktop ? "200px" : "100px",
              objectFit: "cover",
              opacity: lesson.locked ? 0.5 : 1,
            }}
          />
          {lesson.locked && (
            <SvgIcon
              sx={{ height: "64px", width: "64px", position: "absolute" }}
            >
              <IC_LOCK />
            </SvgIcon>
          )}
        </Stack>
        <Stack sx={{ p: "12px" }}>
          <Typography fontFamily={"Space Grotesk"} fontSize={14}>
            Lesson # {lesson.index}
          </Typography>
          <Typography flex={1} fontSize={16} fontWeight={500}>
            {lesson.title}
          </Typography>

          <Typography
            sx={{
              background: "#333",
              alignSelf: "flex-start",
              p: "4px",
              px: "8px",
              borderRadius: "6px",
            }}
            fontSize={12}
            fontWeight={400}
          >
            {formatVideoDuration(lesson.duration || 0)}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  async function onVideoProgress(seconds: number) {
    if (!activeLesson || activeLesson.progress?.quizResult === "passed") return;
    try {
      await RtmSetLessonProgress({
        chapter: chapterId!,
        lesson: activeLesson!.id,
        progress: seconds,
      });
    } catch (err: any) {
      console.error("Error updating progress data. ", err);
    }
    setBusy(false);
  }

  function onVideoEnded() {
    // Show the quiz
    // if (activeLesson?.progress?.quizResult === "passed") {
    //   // Go to next lesson.
    //   const nextLesson = lessons.find(
    //     (l) => l.index === activeLesson?.index! + 1
    //   );
    //   if (nextLesson) {
    //     setActiveLesson(nextLesson);
    //   }
    // } else {
    //   setQuiz(activeLesson?.quiz);
    // }
    setQuiz(activeLesson?.quiz);
  }

  async function onQuizComplete(result: "passed" | "failed", progress: Quiz) {
    // Save progress.
    await RtmSetLessonProgress({
      chapter: chapterId!,
      lesson: activeLesson!.id,
      quiz: progress,
      quizResult: result,
    });
    if (result === "passed") {
      // Go to next lesson.
      const nextLesson = lessons.find(
        (l) => l.index === activeLesson?.index! + 1
      );
      if (nextLesson) {
        setActiveLesson(nextLesson);
      }
    }
    setQuiz(undefined);
  }

  useEffect(() => {
    load();
    setShowSidebar(false);
  }, []);

  useEffect(() => {
    if (activeLesson) {
      window.history.pushState(
        null,
        activeLesson?.title || chapter?.title || "ATU",
        `/dashboard/library/${chapterId}/${activeLesson!.id}`
      );
      const content = activeLesson.content ? activeLesson.content.trim() : "{}";

      try {
        const parsedContent = JSON.parse(content);
        editor.replaceBlocks(editor.document, parsedContent);
      } catch (error) {
        console.error("JSON parsing failed:", error, "Content:", content);
      }
      // editor.replaceBlocks(editor.document, JSON.parse(activeLesson.content?.trim() ?? "{}"));
    }
  }, [activeLesson]);

  return (
    <Stack sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack direction={"row"} alignItems={"center"}>
        {/* <IconButton onClick={() => navigate("/dashboard/library")}>
          <SvgIcon>
            <Ic_back />
          </SvgIcon>
        </IconButton> */}
        <Typography
          onClick={() => navigate("/dashboard/library")}
          sx={{
            fontFamily: "Space Grotesk",
            fontWeight: 600,
            fontSize: 28,
            opacity: 0.8,
            cursor: "pointer",
            ":hover": {
              textDecoration: "underline",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
          }}
        >
          Your Library
        </Typography>
        <ArrowRight />
        <Typography
          sx={{
            fontFamily: "Space Grotesk",
            fontWeight: 600,
            fontSize: 28,
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
          }}
        >
          {chapter?.title}
        </Typography>
      </Stack>
      <Divider />
      {/* The container is like youtube player, with video and content on one side, and lessons on sidebar */}
      <Stack
        direction={"row"}
        sx={{
          overflow: "hidden",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            overflowX: "hidden",
            overflowY: "visible",
          },
        }}
        rowGap={"8px"}
        columnGap={"8px"}
      >
        {/* Content Container  */}
        <Stack
          sx={{
            overflow: "auto",
            [theme.breakpoints.down("md")]: {
              height: "auto",
              overflow: "visible",
            },
            zIndex: 1,
          }}
          flex={1}
          spacing={"18px"}
        >
          <Stack position={isDesktop ? "relative" : undefined}>
            <ReactPlayer
              url={activeLesson ? activeLesson.video : chapter?.demo}
              style={{ borderRadius: "12px" }}
              height={isDesktop ? "600px" : "auto"}
              width={"100%"}
              controls
              onProgress={(props) => {
                onVideoProgress(props.playedSeconds);
              }}
              onEnded={onVideoEnded}
              playing={isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
            />
            {!quiz && activeLesson && (
              <Button
                sx={{width: "12rem", marginTop: "1rem"}}
                variant="contained"
                onClick={() => {
                  setQuiz(activeLesson?.quiz);
                  setIsPlaying(false);
                }}
              >
                Attempt quiz
              </Button>
            )}

            {quiz && (
              // <Dialog open hideBackdrop onClose={() => setQuiz(undefined)} fullWidth>
              //   <DialogTitle>
              // <Stack direction={"row"} spacing={"24px"} sx={{ py: "12px" }}>
              //   <Typography>Quiz</Typography>
              // </Stack>
              // </DialogTitle>
              // <DialogContent>
              <QuizView
                quiz={quiz}
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                }}
                onComplete={onQuizComplete}
                onClose={() => setQuiz(undefined)}
              />
              //   </DialogContent>
              // </Dialog>
            )}
          </Stack>
          <Stack>
            {/* Title of the video */}
            <Typography fontFamily={"Space Grotesk"}>
              Chapter # {chapter?.index}: {chapter?.title}
            </Typography>
            <Typography fontSize={26}>
              {activeLesson ? activeLesson.title : "Introduction"}
            </Typography>
          </Stack>
          {/* Content of the lesson/chapter  */}

          <BlockNoteView
            editor={editor}
            editable={false}
            style={{ zIndex: 1 }}
            theme={darkDefaultTheme}
            onClick={(e: React.BaseSyntheticEvent) => {
              if (e.target.tagName === "IMG") {
                setImageData({
                  src: e.target.src,
                  alt: e.target.alt,
                });
              }
            }}
          />
          {imageData && (
            <ImageModal
              src={imageData.src}
              alt={imageData.alt}
              onClose={() => setImageData(undefined)}
            />
          )}
          <Stack spacing={"8px"}>
            <Typography>Tags</Typography>
            {activeLesson && (
              <Stack direction={"row"} spacing={"8px"}>
                {activeLesson.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            )}
            {!activeLesson && (
              <Stack direction={"row"} spacing={"8px"}>
                {chapter?.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Divider />

        {/* Sidebar  */}
        <Stack
          spacing={"18px"}
          sx={{
            width: "400px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Typography fontFamily={"Space Grotesk"}>Lessons</Typography>
          {lessons.map((lesson) => RenderLesson(lesson))}
        </Stack>
      </Stack>
    </Stack>
  );
}
